const Toolbar = () => import("@/components/core/Toolbar");
const Dashboard = () => import("@/views/onestream/Dashboard");
const Devices = () => import("@/views/onestream/Devices");
const Settings = () => import("@/views/onestream/Settings");
const Statistic = () => import("@/views/onestream/Statistic");
const title = "ONEstream";
const key = "onestream";

export default [
  {
    path: "/property/:propertyId/onestream/dashboard",
    name: "onestreamDashboard",
    components: {
      default: Statistic,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onestream/devices",
    name: "onestreamDevices",
    components: {
      default: Devices,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onestream/settings",
    name: "onestreamSettings",
    components: {
      default: Settings,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
];
