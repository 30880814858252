import api from "@api";
import * as types from "../types";
import { getSchema } from "@/utils/schema";
import Vue from "vue";

const state = {
  items: [],
  status: void 0,
};

const getters = {
  itemSchema: (state) => {
    const schema = getSchema({
      items: [
        {
          type: "autocomplete",
          value: "items",
          multiple: true,
          deletableChips: true,
          chips: true,
          items: state.items,
          itemValue: "iso1",
          itemText: (item) => {
            return `${item.name} (${item.iso1} ${item.local})`;
          },
        },
      ],
    });
    return schema;
  },
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("language.name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate("language.local"),
        value: "local",
      },
      {
        text: Vue.i18n.translate("language.language"),
        value: "iso1",
      },
    ];
  },
};

// actions
const actions = {
  async indexGlobalLanguage({ commit }) {
    commit(types.REQUEST);
    try {
      commit(types.SUCCESS_REQUEST, await api.indexLanguage());
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
