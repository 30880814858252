import api from "@api";
import * as types from "../types";
import { each } from "bluebird";

const state = {
  files: [],
  status: void 0,
  itemById: {},
  statusById: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
};

// actions
const actions = {
  setUploading({ commit }, val) {
    if (val) {
      commit(types.REQUEST_SAVE);
    } else {
      commit(types.SUCCESS_REQUEST_SAVE);
    }
  },
  async indexMediapoolFiles({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexMediapoolFiles({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async deleteMediapool({ commit }, { id, propertyId }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteMediapool({
          id,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    value.body.items.map((element) => {
      return (element.selected = false);
    });
    state.files = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusDelete = "success";
  },

  [types.REQUEST_BY_ID](state) {
    state.statusById = "fetching";
  },
  [types.FAILURE_REQUEST_BY_ID](state) {
    state.statusById = "failure";
  },
  [types.SUCCESS_REQUEST_BY_ID](state, value) {
    state.statusById = "success";
    state.itemById = value.body;
  },

  SET_SELECTED_FILE(state, value) {
    state.files[value.index].selected = value.value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
