import api from "@api/onestream";
import * as types from "../../types";

const state = {
  all: [],
  devices: [],
  mappings: [],
  status: null,
  service_usage: [],
  total_usage: 0,
};

const CONN_SUCC = "conn_succ";
const CONN_FAIL = "conn_fail";
const START = "start";
const STOP = "stop";

function getGroupedByTime(data) {
  return data.reduce((groups, item) => {
    const date = item.timestamp.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});
}

function getGroupedByAppId(data) {
  return data.reduce((groups, item) => {
    const appId = item.usage?.appId;
    if (!appId) return groups;
    if (!groups[appId]) {
      groups[appId] = [];
    }
    groups[appId].push(item);
    return groups;
  }, {});
}

const getters = {
  getDeviceNameById: (state) => (id) => {
    return state.devices.find((i) => i.id === id)?.name;
  },

  getServiceNameById: (state) => (id) => {
    return state.mappings.find((i) => i.value === id)?.name;
  },

  getRequestStats: (state) => {
    if (!state.all.length) return [];
    let res = [];

    /// TODO: We should do all of that in the backend in the future for performance reasons

    const grouped = getGroupedByTime(state.all);
    for (const [key, value] of Object.entries(grouped)) {
      const succ = value.filter((i) => i.usage.type === CONN_SUCC).length;
      const fail = value.filter((i) => i.usage.type === CONN_FAIL).length;
      const total = succ + fail;
      res.push([key, succ, fail, total]);
    }

    return res;
  },

  getDonglesStats: (state, getters) => {
    if (!state.all.length) return [];

    /// TODO: We should do all of that in the backend in the future for performance reasons

    let res = [];
    for (let usage of state.all) {
      if (usage.usage.duration !== 0) {
        res.push([
          usage.usage.name,
          parseFloat(usage.usage.duration),
          usage.timestamp.split("T")[0],
        ]);
      }
    }

    return res;
  },

  getTotalUsage: (state) => {
    if (!state.all.length) return [];
    let res = [];

    /// TODO: We should do all of that in the backend in the future for performance reasons

    const grouped = getGroupedByTime(state.all);
    for (const [key, value] of Object.entries(grouped)) {
      const usages = value.filter((i) => i.usage.type === STOP);
      let usage = 0;
      for (let i of usages) {
        usage += i.usage.duration;
      }
      let avrg = 0;
      if (usages.length > 0) avrg = usage / usages.length;
      res.push([key, usage, avrg]);
    }

    return res;
  },

  getServiceMappings: (state) => {
    return state.mappings;
  },

  getServiceUsage: (state, getters) => {
    if (!state.service_usage) return [];
    return state.service_usage;
  },

  getTotalOverallUsage: (state) => {
    return state.total_usage;
  },
};

const actions = {
  async fetchStats({ commit }, propertyId) {
    try {
      const res = await api.indexStats({
        propertyId,
      });

      if (!res.body.items) {
        commit("SUCC_STATS_INDEX", []);
        return;
      }

      commit("SUCC_STATS_INDEX", res.body.items);
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async fetchDongles({ commit }, propertyId) {
    try {
      const res = await api.indexDevice({
        propertyId,
      });

      if (!res.body.items) {
        commit("SUCC_DEVICE_INDEX", []);
        return;
      }

      commit("SUCC_DEVICE_INDEX", res.body.items);
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async fetchServiceUsage({ state, commit }, propertyId) {
    try {
      const res = await api.indexServiceUsage({
        propertyId,
      });

      if (!res.body.items) {
        return;
      }

      commit("SUCC_SERVICE_USAGE", res.body.items);
      commit("SUCC_TOTAL_OVERALL_USAGE", res.body.total);
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async fetchServiceMappings({ state, commit }, propertyId) {
    try {
      const res = await api.indexServiceMappings({
        propertyId,
      });

      if (!res.body.data) {
        commit("SUCC_MAPPINGS_INDEX", []);
        return;
      }

      res.body.data.map((i) => {
        let tmp = new Date(i.lastUpdate);
        if (i.lastUpdate && tmp > new Date(2000, 1, 1)) {
          i.lastUpdate = tmp;
        } else {
          i.lastUpdate = null;
        }
      });

      commit("SUCC_MAPPINGS_INDEX", res.body.data);
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },

  async insertOrUpdateMapping({ state, commit }, { mapping, propertyId }) {
    if (mapping._id) {
      try {
        const res = await api.updateMapping({
          id: mapping._id,
          item: mapping,
          propertyId: propertyId,
        });

        commit("SUCC_MAPPING_UPDATE");
        return res.body.data._id;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        commit(types.FAILURE_REQUEST);
      }
    } else {
      try {
        const res = await api.saveMapping({
          item: mapping,
          propertyId: propertyId,
        });
        commit("SUCC_MAPPING_INSERT");

        mapping._id = res.body.data._id;
        state.mappings[state.mappings.length - 1] = Object.assign({}, mapping);
        return res.body.data._id;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        commit(types.FAILURE_REQUEST);
      }
    }
  },

  deleteMapping: async function ({ state, commit }, { id, propertyId }) {
    if (id) {
      try {
        const res = await api.deleteMapping({ id: id, propertyId: propertyId });

        for (let i = state.mappings.length - 1; i >= 0; --i) {
          if (state.mappings[i]._id === id) {
            state.mappings.splice(i, 1);
            break;
          }
        }

        commit("SUCC_MAPPING_DELETE");
        return res.body.data._id;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        commit(types.FAILURE_REQUEST);
      }
    } else {
      let found = false;
      for (let i = state.mappings.length - 1; i >= 0; --i) {
        if (!state.mappings[i]._id) {
          state.mappings.splice(i, 1);
          found = true;
        }
      }

      if (found) {
        commit("SUCC_MAPPING_DELETE");
      } else {
        commit(types.FAILURE_REQUEST);
      }
    }
  },
};

const mutations = {
  ["SUCC_STATS_INDEX"](state, items) {
    state.status = "success";
    state.all = items;
  },
  ["SUCC_DEVICE_INDEX"](state, devices) {
    state.status = "success";
    state.devices = devices;
  },
  ["SUCC_MAPPINGS_INDEX"](state, mappings) {
    state.status = "success";
    state.mappings = mappings;
  },
  ["SUCC_MAPPING_INSERT"](state) {
    state.status = "success";
  },
  ["SUCC_MAPPING_UPDATE"](state) {
    state.status = "success";
  },
  ["SUCC_MAPPING_DELETE"](state) {
    state.status = "success";
  },
  ["SUCC_SERVICE_USAGE"](state, usage) {
    state.service_usage = usage;
    state.status = "success";
  },
  ["SUCC_TOTAL_OVERALL_USAGE"](state, usage) {
    state.total_usage = usage;
    state.status = "success";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
