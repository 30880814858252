import {
  postToken,
  getInfo,
  logout,
  verifyTwoFactorAuthCode,
  resendTwoFactorAuthCode,
} from "@/api/auth";
import router from "@/router";

const state = {
  statusToken: undefined,
  accessToken: undefined,
  statusInfo: undefined,
  info: {},
};

const actions = {
  token({ commit, dispatch }, { email, password, nextpage = "/" }) {
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true, { root: true });
      commit("FETCHING_TOKEN");
      try {
        commit(
          "SUCCESS_FETCHING_TOKEN",
          await postToken({
            email,
            password,
          })
        );

        if (email) {
          localStorage.setItem("email", email);
        }
        await dispatch("info");
        await dispatch("property/indexProperty", null, { root: true });
        this._vm.$ga.event({
          eventCategory: "Login",
          eventAction: "authenticate success",
          eventLabel: router.currentRoute.query.email || email,
          eventValue: 1,
        });
        commit("SET_LOADING", false, { root: true });
        resolve();
      } catch (err) {
        if (err.status == 403 && err.body == "2fa required") {
          commit("SET_LOADING", false, { root: true });
          reject("2fa required");
          return;
        }
        this._vm.$ga.event({
          eventCategory: "Login",
          eventAction: "authenticate failed",
          eventLabel: router.currentRoute.query.email,
          eventValue: 1,
        });
        commit("SET_LOADING", false, { root: true });
        commit("FAILURE_FETCHING_TOKEN", err);
        router.push({ name: "login", query: { nextpage } });
        reject();
      }
    });
  },
  async verifyTwoFactorAuthCode({ commit, dispatch }, { email, code }) {
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true, { root: true });
      commit("FETCHING_TOKEN");
      try {
        commit(
          "SUCCESS_FETCHING_TOKEN",
          await verifyTwoFactorAuthCode({
            email,
            code,
          })
        );
        await dispatch("info");
        await dispatch("property/indexProperty", null, { root: true });
      } catch (err) {
        this._vm.$ga.event({
          eventCategory: "Login",
          eventAction: "authenticate failed",
          eventLabel: router.currentRoute.query.email,
          eventValue: 1,
        });
        commit("SET_LOADING", false, { root: true });
        commit("FAILURE_FETCHING_TOKEN", err);
        reject();
        return;
      }

      if (email) {
        localStorage.setItem("email", email);
      }

      this._vm.$ga.event({
        eventCategory: "Login",
        eventAction: "authenticate success",
        eventLabel: router.currentRoute.query.email || email,
        eventValue: 1,
      });

      commit("SET_LOADING", false, { root: true });
      resolve();
    });
  },
  async resendTwoFactorAuthCode({ commit }, { email }) {
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true, { root: true });
      try {
        await resendTwoFactorAuthCode({
          email,
        });
      } catch (err) {
        commit("SET_LOADING", false, { root: true });
        reject();
        return;
      }

      commit("SET_LOADING", false, { root: true });
      resolve();
    });
  },
  async info({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      commit("FETCHING_INFO");
      try {
        commit("SUCCESS_FETCHING_INFO", await getInfo());
        this._vm.$ga.set({ userId: state.info.id });
        resolve(state.info);
      } catch (err) {
        commit("FAILURE_FETCHING_INFO", err);
        reject();
      }
    });
  },
  async logout({ commit, state }) {
    commit("FETCHING_LOGOUT");
    try {
      const { email = router.currentRoute.query.email } = state.info;
      commit("SUCCESS_FETCHING_LOGOUT", await logout());
      this._vm.$ga.set({ userId: null });
      localStorage.removeItem("email");
      router.push({
        name: "login",
        query: { email },
      });
    } catch (err) {
      commit("FAILURE_FETCHING_LOGOUT", err);
    }
  },
};

const getters = {
  getPermissions: (state, unused, rootState) => {
    let propertyPermissions = [];
    for (let property in state.info.propertyPermissions) {
      if (rootState.property.propertyId === property) {
        propertyPermissions = state.info.propertyPermissions[property];
      }
    }

    return [...propertyPermissions, ...state.info.globalPermissions];
  },
};

const mutations = {
  FETCHING_LOGOUT(state) {
    state.statusLogout = "fetching";
  },
  SUCCESS_FETCHING_LOGOUT(state) {
    state.statusLogout = "success";
    state.accessToken = undefined;
    state.info = {};
  },
  FAILURE_FETCHING_LOGOUT(state) {
    state.statusLogout = "failure";
  },
  FETCHING_TOKEN(state) {
    state.statusToken = "fetching";
  },
  SUCCESS_FETCHING_TOKEN(state, { body }) {
    const { accessToken } = body;
    state.accessToken = accessToken;
    state.statusToken = "success";
  },
  FAILURE_FETCHING_TOKEN(state) {
    state.statusToken = "failure";
  },
  FETCHING_INFO(state) {
    state.statusInfo = "fetching";
  },
  SUCCESS_FETCHING_INFO(state, { body }) {
    state.info = body;
    state.statusInfo = "success";
  },
  FAILURE_FETCHING_INFO(state) {
    state.statusInfo = "failure";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
