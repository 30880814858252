export const yesNoOverride = (value) => {
  return [{ value: value ? "Yes" : "No" }];
};

export const postNoPostOverride = (value) => {
  return [{ value: value ? "NO_POST" : "POST" }];
};

export const checkMark = (value) => {
  if (value) {
    return [{ icon: "mdi-check-circle", color: "success" }];
  }
  return [{ icon: "mdi-close", color: "error" }];
};

export const checkVerified = (value) => {
  if (value === true) {
    return [{ icon: "mdi-check-circle", color: "success" }];
  } else if (value === false) {
    return [{ icon: "mdi-close", color: "error" }];
  }
  return [];
};

export const profileOverride = (value, icon) => {
  if (value && icon) {
    return [{ icon, value }];
  }
  return [{ value }];
};

export const onlineOfflineOverride = (value) => {
  if (value) {
    return [{ icon: "mdi-lan-connect", color: "success" }];
  }
  return [{ icon: "mdi-lan-disconnect", color: "error" }];
};

export const imageOverride = (value = {}) => {
  // this is because we receive null from the api when no image is set
  if (value == null) {
    value = {};
  }

  return [{ image: true, value: { id: value.id, ext: value.ext } }];
};

export const colorOverride = (value) => {
  return [{ color: true, value }];
};

export const connectStatusOverride = (value) => {
  if (!value || value == "") {
    return [{ icon: "mdi-minus-circle", color: "error" }];
  }
  return [{ icon: "mdi-check-circle", color: "success" }];
};
