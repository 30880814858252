import {
  InfoTextResource,
  InfoTextIndexResource,
  LanguagePropertyResource,
  LanguageIndexResource,
} from "./resources";

export default {
  indexInfoText({ propertyId }) {
    return InfoTextIndexResource.get({
      propertyId,
    });
  },
  updateInfoText({ items, propertyId }) {
    return InfoTextResource.update(
      {
        propertyId,
      },
      {
        items,
      }
    );
  },
  indexLanguage({ propertyId }) {
    return LanguageIndexResource.get({
      propertyId,
    });
  },
  saveLanguage({ items = [], propertyId }) {
    return LanguagePropertyResource.update(
      {
        propertyId,
      },
      items
    );
  },
};
