import api from "@api/onespeedGateway";
import router from "@/router";
import { DateTime } from "luxon";
import Vue from "vue";

const state = {
  sessionCategories: [],
  statusSession: void 0,
  sessionValues: [],
  bandwidthValuesDown: [],
  bandwidthValuesUp: [],
  bandwidthCategories: [],
  statusBandwidth: void 0,
  statusSessionNow: void 0,
  statusBandwidthNow: void 0,
  bandwidthDownNow: "0",
  bandwidthUpNow: "0",
  sessionNow: "0",
  revenueCategories: [],
  statusRevenue: void 0,
  revenueValues: [],
  topSessionsValues: [],
  topSessionsTotal: 0,
  statusTopSessions: void 0,
  statusTopSessionsCSV: void 0,
};

const getters = {
  bandwidthShowItems: () => {
    return [
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.stats.downloadAndUpload"
        ),
        value: "both",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.stats.download"
        ),
        value: "download",
      },
      {
        text: Vue.i18n.translate("store.modules.onespeedGateway.stats.upload"),
        value: "upload",
      },
    ];
  },
  timeItems: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.onespeedGateway.stats.hourly"),
        value: "hourly",
      },
      {
        text: Vue.i18n.translate("store.modules.onespeedGateway.stats.daily"),
        value: "daily",
      },
      {
        text: Vue.i18n.translate("store.modules.onespeedGateway.stats.monthly"),
        value: "monthly",
      },
      {
        text: Vue.i18n.translate("store.modules.onespeedGateway.stats.yearly"),
        value: "yearly",
      },
    ];
  },
};

const actions = {
  async getBandwidth(
    { commit },
    {
      propertyId = router.currentRoute.params.propertyId,
      startDateTime,
      endDateTime,
      unit,
      binningType,
    }
  ) {
    commit("FETCHING_BANDWIDTH");
    try {
      if (startDateTime) {
        startDateTime = startDateTime
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      if (endDateTime) {
        endDateTime = endDateTime
          .set({
            hour: 24,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      const downResponse = await api.getBandwidthStats({
        propertyId,
        startDateTime,
        endDateTime,
        unit,
        binningType,
        valueType: "down",
      });
      const upResponse = await api.getBandwidthStats({
        propertyId,
        startDateTime,
        endDateTime,
        unit,
        binningType,
        valueType: "up",
      });
      commit("SUCCESS_FETCHING_BANDWIDTH", {
        down: downResponse.body,
        up: upResponse.body,
      });
    } catch (e) {
      commit("FAILURE_FETCHING_BANDWIDTH");
    }
  },
  async getSession(
    { commit },
    {
      propertyId = router.currentRoute.params.propertyId,
      startDateTime,
      endDateTime,
      binningType,
    }
  ) {
    commit("FETCHING_SESSION");
    try {
      if (startDateTime) {
        startDateTime = startDateTime
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      if (endDateTime) {
        endDateTime = endDateTime
          .set({
            hour: 24,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      commit(
        "SUCCESS_FETCHING_SESSION",
        await api.getSessionStats({
          propertyId,
          startDateTime,
          endDateTime,
          binningType,
        })
      );
    } catch (e) {
      commit("FAILURE_FETCHING_SESSION");
    }
  },
  async getBandwidthNow(
    { commit },
    { propertyId = router.currentRoute.params.propertyId }
  ) {
    commit("FETCHING_BANDWIDTH_NOW");
    try {
      commit(
        "SUCCESS_FETCHING_BANDWIDTH_NOW",
        await api.getBandwidthStatsNow({
          propertyId,
        })
      );
    } catch (e) {
      commit("FAILURE_FETCHING_BANDWIDTH_NOW");
    }
  },
  async getSessionNow(
    { commit },
    { propertyId = router.currentRoute.params.propertyId }
  ) {
    commit("FETCHING_SESSION_NOW");
    try {
      commit(
        "SUCCESS_FETCHING_SESSION_NOW",
        await api.getSessionStatsNow({
          propertyId,
        })
      );
    } catch (e) {
      commit("FAILURE_FETCHING_SESSION_NOW");
    }
  },
  async getRevenueStats(
    { commit },
    {
      propertyId = router.currentRoute.params.propertyId,
      startDateTime,
      endDateTime,
      binningType,
    }
  ) {
    commit("FETCHING_REVENUE");
    try {
      if (startDateTime) {
        startDateTime = startDateTime
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      if (endDateTime) {
        endDateTime = endDateTime
          .set({
            hour: 24,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      commit(
        "SUCCESS_FETCHING_REVENUE",
        await api.getRevenueStats({
          propertyId,
          startDateTime,
          endDateTime,
          binningType,
        })
      );
    } catch (e) {
      commit("FAILURE_FETCHING_REVENUE");
    }
  },
  async getTopSessionsStats(
    { commit },
    {
      propertyId = router.currentRoute.params.propertyId,
      startDateTime,
      endDateTime,
      limit,
      skip,
      search,
    }
  ) {
    commit("FETCHING_TOP_SESSIONS");
    try {
      if (startDateTime) {
        startDateTime = startDateTime
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      if (endDateTime) {
        endDateTime = endDateTime
          .set({
            hour: 24,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      commit(
        "SUCCESS_FETCHING_TOP_SESSIONS",
        await api.getTopSessionsStats({
          propertyId,
          startDateTime,
          endDateTime,
          limit,
          skip,
          search,
        })
      );
    } catch (e) {
      commit("FAILURE_FETCHING_TOP_SESSIONS");
    }
  },
  async getTopSessionsStatsCSV(
    { commit },
    {
      propertyId = router.currentRoute.params.propertyId,
      startDateTime,
      endDateTime,
      search,
    }
  ) {
    commit("FETCHING_TOP_SESSIONS_CSV");
    try {
      if (startDateTime) {
        startDateTime = startDateTime
          .set({
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      if (endDateTime) {
        endDateTime = endDateTime
          .set({
            hour: 24,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO();
      }
      commit(
        "SUCCESS_FETCHING_TOP_SESSIONS_CSV",
        await api.getTopSessionsStatsCSV({
          propertyId,
          startDateTime,
          endDateTime,
          search,
        })
      );
    } catch (e) {
      commit("FAILURE_FETCHING_TOP_SESSIONS_CSV");
    }
  },
};

const mutations = {
  FETCHING_BANDWIDTH(state) {
    state.statusBandwidth = "fetching";
  },
  SUCCESS_FETCHING_BANDWIDTH(
    state,
    { down = { items: [] }, up = { items: [] } }
  ) {
    const itemsDown = down.items;
    /*[
      { label: "2021-04-18T02:34:00Z", value: 0.937633 },
      { label: "2021-04-18T02:34:00Z", value: 3.747722 },
      { label: "2021-04-18T02:34:00Z", value: 0.943957 },
      { label: "2021-04-18T02:34:00Z", value: 0.809338 },
      { label: "2021-04-18T02:34:00Z", value: 0.872468 },
      { label: "2021-04-18T02:34:00Z", value: 0.686374 },
      { label: "2021-04-18T02:34:00Z", value: 1.654847 },
      { label: "2021-04-18T02:34:00Z", value: 5.552045 },
      { label: "2021-04-18T02:34:00Z", value: 2.262551 },
      { label: "2021-04-18T02:34:00Z", value: 7.167631 },
      { label: "2021-04-18T02:34:00Z", value: 0 },
      { label: "2021-04-18T02:34:00Z", value: 4.651236 },
      { label: "2021-04-18T02:34:00Z", value: 0.964185 },
      { label: "2021-04-18T02:34:00Z", value: 0.80768 },
      { label: "2021-04-18T02:34:00Z", value: 1.276784 },
      { label: "2021-04-18T02:34:00Z", value: 1.945468 },
      { label: "2021-04-18T02:34:00Z", value: 5.620767 },
    ];*/
    const itemsUp = up.items;
    /*[
      { label: "2021-04-18T02:34:00Z", value: 0.137633 },
      { label: "2021-04-18T02:34:00Z", value: 0.147722 },
      { label: "2021-04-18T02:34:00Z", value: 0.143957 },
      { label: "2021-04-18T02:34:00Z", value: 0.109338 },
      { label: "2021-04-18T02:34:00Z", value: 0.172468 },
      { label: "2021-04-18T02:34:00Z", value: 0.186374 },
      { label: "2021-04-18T02:34:00Z", value: 0.354847 },
      { label: "2021-04-18T02:34:00Z", value: 0.452045 },
      { label: "2021-04-18T02:34:00Z", value: 0.262551 },
      { label: "2021-04-18T02:34:00Z", value: 0.167631 },
      { label: "2021-04-18T02:34:00Z", value: 0 },
      { label: "2021-04-18T02:34:00Z", value: 0.051236 },
      { label: "2021-04-18T02:34:00Z", value: 0.964185 },
      { label: "2021-04-18T02:34:00Z", value: 0.80768 },
      { label: "2021-04-18T02:34:00Z", value: 0.276784 },
      { label: "2021-04-18T02:34:00Z", value: 0.445468 },
      { label: "2021-04-18T02:34:00Z", value: 0.920767 },
    ];*/

    state.bandwidthCategories = itemsDown.map((item) => {
      const utc = DateTime.fromISO(item.label, { zone: "utc" });
      const local = utc.toLocal();
      return `${local.toLocaleString(DateTime.DATETIME_SHORT)} ${
        local.offsetNameShort
      }`;
    });
    state.bandwidthValuesDown = itemsDown.map((item) => {
      return item.value;
    });
    state.bandwidthValuesUp = itemsUp.map((item) => {
      return item.value;
    });
    state.statusBandwidth = "success";
  },

  FAILURE_FETCHING_BANDWIDTH(state) {
    state.statusBandwidth = "failure";
  },

  FETCHING_SESSION(state) {
    state.statusSession = "fetching";
  },
  SUCCESS_FETCHING_SESSION(state, { body = {} }) {
    let { items = [] } = body;
    // testdata
    /*items = [
      { label: "2021-04-18T02:34:00Z", value: 4 },
      { label: "2021-04-18T02:34:00Z", value: 7 },
      { label: "2021-04-18T02:34:00Z", value: 6 },
      { label: "2021-04-18T02:34:00Z", value: 6 },
      { label: "2021-04-18T02:34:00Z", value: 34 },
      { label: "2021-04-18T02:34:00Z", value: 45 },
      { label: "2021-04-18T02:34:00Z", value: 42 },
      { label: "2021-04-18T02:34:00Z", value: 40 },
      { label: "2021-04-18T02:34:00Z", value: 20 },
      { label: "2021-04-18T02:34:00Z", value: 50 },
      { label: "2021-04-18T02:34:00Z", value: 1 },
      { label: "2021-04-18T02:34:00Z", value: 4 },
      { label: "2021-04-18T02:34:00Z", value: 4 },
      { label: "2021-04-18T02:34:00Z", value: 5 },
      { label: "2021-04-18T02:34:00Z", value: 6 },
      { label: "2021-04-18T02:34:00Z", value: 6 },
      { label: "2021-04-18T02:34:00Z", value: 7 },
    ];*/
    state.sessionCategories = items.map((item) => {
      const utc = DateTime.fromISO(item.label, { zone: "utc" });
      const local = utc.toLocal();
      return `${local.toLocaleString(DateTime.DATETIME_SHORT)} ${
        local.offsetNameShort
      }`;
    });
    state.sessionValues = items.map((item) => {
      return item.value;
    });
    state.statusSession = "success";
  },
  FAILURE_FETCHING_SESSION(state) {
    state.statusSession = "failure";
  },

  FETCHING_BANDWIDTH_NOW(state) {
    state.statusBandwidthNow = "fetching";
  },
  SUCCESS_FETCHING_BANDWIDTH_NOW(state, { body = {} }) {
    const { down = "N/A", up = "N/A" } = body;
    state.bandwidthDownNow = down;
    state.bandwidthUpNow = up;
    state.statusBandwidthNow = "success";
  },
  FAILURE_FETCHING_BANDWIDTH_NOW(state) {
    state.statusBandwidthNow = "failure";
  },

  FETCHING_SESSION_NOW(state) {
    state.statusSessionNow = "fetching";
  },
  SUCCESS_FETCHING_SESSION_NOW(state, { body = {} }) {
    const { sessions = "N/A" } = body;
    state.sessionNow = sessions.toString();
    state.statusSessionNow = "success";
  },
  FAILURE_FETCHING_SESSION_NOW(state) {
    state.statusSessionNow = "failure";
  },
  FETCHING_REVENUE(state) {
    state.statusRevenue = "fetching";
  },
  SUCCESS_FETCHING_REVENUE(state, { body = {} }) {
    let { items = [] } = body;
    // testdata
    /*items = [
      { label: "2021-04-18T02:34:00Z", value: 4 },
      { label: "2021-04-18T02:34:00Z", value: 7 },
      { label: "2021-04-18T02:34:00Z", value: 6 },
      { label: "2021-04-18T02:34:00Z", value: 6 },
      { label: "2021-04-18T02:34:00Z", value: 34 },
      { label: "2021-04-18T02:34:00Z", value: 45 },
      { label: "2021-04-18T02:34:00Z", value: 42 },
      { label: "2021-04-18T02:34:00Z", value: 40 },
      { label: "2021-04-18T02:34:00Z", value: 20 },
      { label: "2021-04-18T02:34:00Z", value: 50 },
      { label: "2021-04-18T02:34:00Z", value: 1 },
      { label: "2021-04-18T02:34:00Z", value: 4 },
      { label: "2021-04-18T02:34:00Z", value: 4 },
      { label: "2021-04-18T02:34:00Z", value: 5 },
      { label: "2021-04-18T02:34:00Z", value: 6 },
      { label: "2021-04-18T02:34:00Z", value: 6 },
      { label: "2021-04-18T02:34:00Z", value: 7 },
    ];*/
    state.revenueCategories = items.map((item) => {
      const utc = DateTime.fromISO(item.label, { zone: "utc" });
      const local = utc.toLocal();
      return `${local.toLocaleString(DateTime.DATETIME_SHORT)} ${
        local.offsetNameShort
      }`;
    });
    state.revenueValues = items.map((item) => {
      return item.value;
    });
    state.statusRevenue = "success";
  },
  FAILURE_FETCHING_REVENUE(state) {
    state.statusRevenue = "failure";
  },
  FETCHING_TOP_SESSIONS(state) {
    state.statusTopSessions = "fetching";
  },
  SUCCESS_FETCHING_TOP_SESSIONS(state, { body = {} }) {
    let { items = [], totalCount = 0 } = body;

    state.topSessionsValues = items;
    state.topSessionsTotal = totalCount;
    state.statusTopSessions = "success";
  },
  FAILURE_FETCHING_TOP_SESSIONS(state) {
    state.statusTopSessions = "failure";
  },
  FETCHING_TOP_SESSIONS_CSV(state) {
    state.statusTopSessionsCSV = "fetching";
  },
  SUCCESS_FETCHING_TOP_SESSIONS_CSV(state, value) {
    state.statusTopSessionsCSV = "success";
    const filename = (value.headers.get("content-disposition") || "").split(
      "filename="
    )[1];
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(value.body);
    hiddenElement.target = "_blank";
    hiddenElement.download = `${filename}.csv`;
    hiddenElement.click();
  },
  FAILURE_FETCHING_TOP_SESSIONS_CSV(state) {
    state.statusTopSessionsCSV = "failure";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
