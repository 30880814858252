<template>
  <v-autocomplete
    class="mb-3"
    single-line
    open-on-clear
    :loading="statusProperties === 'fetching'"
    :autofocus="isEmpty(selectedProperty) && statusProperties === 'success'"
    hide-details
    :items="properties"
    :filter="customFilter"
    item-text="name"
    item-value="id"
    label="Property"
    v-model="selectedProperty"
    :search-input.sync="propertyFilterTerm"
  >
    <template slot="item" slot-scope="{ item }">
      <!--<v-list-item-avatar color="primary">{{
        item.name.charAt(0)
      }}</v-list-item-avatar>-->
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapState } from "vuex";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";

export default {
  data() {
    return {
      isEditing: false,
      selected: void 0,
      propertyFilterTerm: "",
      isEmpty,
    };
  },
  methods: {
    ...mapActions("property", ["setSelectedProperty"]),
    customFilter(value, queryText) {
      return (
        value.name
          .toLowerCase()
          .includes(
            queryText.toLowerCase(),
            this.propertyFilterTerm.toLowerCase()
          ) ||
        value.id
          .toLowerCase()
          .includes(
            queryText.toLowerCase(),
            this.propertyFilterTerm.toLowerCase()
          )
      );
    },
  },
  computed: {
    ...mapState({
      properties: ({ property }) => property.items,
      statusProperties: ({ property }) => property.status,
    }),
    selectedProperty: {
      get() {
        return this.$store.state.property.selectedProperty;
      },
      set(val) {
        this.$router
          .push(
            this.$route.meta.product
              ? { params: { propertyId: val } }
              : { name: "propertyDashboard", params: { propertyId: val } }
          )
          .catch(() => {});
      },
    },
  },
};
</script>
