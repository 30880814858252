import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/src/styles/main.sass";

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#BF9459",
        secondary: "#424242",
        accent: "#BF9459",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: "#BF9459",
        secondary: "#424242",
        accent: "#BF9459",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});

/*
{
  themes: {
    light: {
      primary: "#BF9459",
      secondary: "#424242",
      accent: "#BF9459",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107"
    }
  },
  iconfont: "md"
}
*/
