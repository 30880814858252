<template>
  <div>
    <v-divider v-if="item.divider"></v-divider>
    <v-subheader
      v-else-if="item.subheader"
      v-html="item.title + 'subheader'"
    ></v-subheader>
    <v-list-group
      :key="item.title"
      :subheader="item.afterSubheader"
      v-else-if="item.items"
      v-model="item.active"
      :group="item.group"
      :exact="item.exact"
      no-action
      :prepend-icon="item.icon"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <template v-for="subItem in item.items">
        <v-list-item
          :exact="subItem.exact"
          v-if="!subItem.group"
          :key="subItem.title"
          :to="
            subItem.property === false
              ? subItem.to
              : getToWithPropertyId(subItem.to)
          "
        >
          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="subItem.icon">
            <v-icon v-html="subItem.icon"></v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-group
          value="true"
          no-action
          sub-group
          v-else-if="subItem.items"
          v-model="subItem.active"
          :group="subItem.group"
          :key="subItem.title"
          :prepend-icon="subItem.icon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="subSubItem in subItem.items">
            <v-list-item
              :exact="subItem.exact"
              :key="subSubItem.title"
              :to="
                subItem.property === false
                  ? subItem.to
                  : getToWithPropertyId(subSubItem.to)
              "
            >
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  v-text="subSubItem.title"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>{{ subSubItem.icon }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list-group>
    <v-list-item
      v-else
      :exact="item.exact"
      :to="item.property === false ? item.to : getToWithPropertyId(item.to)"
    >
      <v-list-item-icon>
        <v-icon v-html="item.icon"></v-icon>
      </v-list-item-icon>
      <v-list-item-title v-text="item.title"></v-list-item-title>
      <v-list-item-action v-if="item.appendIcon">
        <v-btn icon ripple>
          <v-icon>{{ item.appendIcon }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "NavigationDrawerList",
  props: ["item", "iterator"],
  methods: {
    getToWithPropertyId(val) {
      return { ...val, params: { propertyId: this.$route.params.propertyId } };
    },
  },
};
</script>
