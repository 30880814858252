import api from "@api/weather";
import * as types from "../../types";
import set from "lodash/set";

const state = {
  items: [],
  schema: [],
  status: void 0,
  statusUpdate: void 0,
};

// actions
const actions = {
  updateKeyValue({ commit }, { key, value }) {
    commit(types.UPDATE_KEY_VALUE, { key, value });
  },
  async getSettings({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      const [settings, schema] = await Promise.all([
        api.getSettings({ propertyId }),
        api.getSchema({ propertyId }),
      ]);
      commit(types.SUCCESS_REQUEST, { settings, schema });
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async updateSettings({ commit }, { items, propertyId }) {
    commit(types.REQUEST_UPDATE);
    try {
      commit(
        types.SUCCESS_REQUEST_UPDATE,
        await api.updateSettings({
          propertyId,
          items,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_UPDATE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, { settings, schema }) {
    state.status = "success";
    state.items = settings.body;
    state.schema = schema.body.schema;
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.UPDATE_KEY_VALUE](state, { key, value }) {
    set(state.items, key, value);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
