import Vue from "vue";
import Vuex from "vuex";
import * as types from "./types";
import modules from "./modules";

import pathify from "./pathify"; // <-- note the ./ denoting a local file!

Vue.use(Vuex);

// print pathify options if needed
// pathify.debug();

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== "production",
  state: {
    loading: false,
    drawer: true,
    dark: localStorage.getItem("dark") === "true",
    propertyDialog: false,
    propertyDrawer: false,
    languages: [],
    lang: localStorage.getItem("lang") || "en",
  },
  actions: {
    toggleDark({ commit }) {
      commit(types.SET_DARK, !this.state.dark);
    },
  },
  mutations: {
    SET_LANG(state, value) {
      state.lang = value;
      Vue.i18n.set(value);
      localStorage.setItem("lang", value);
    },
    SET_LANGUAGES(state, value) {
      state.languages = value;
    },
    [types.SET_DRAWER](state, value) {
      state.drawer = value;
    },
    [types.SET_PROJECT_DRAWER](state, value) {
      state.propertyDrawer = value;
    },
    [types.SET_DARK](state, value) {
      state.dark = value;
      localStorage.setItem("dark", value);
    },
    [types.SET_LOADING](state, value) {
      state.loading = value;
    },
    [types.SET_PROJECT_DIALOG](state, value) {
      state.propertyDialog = value;
    },
  },
  plugins: [pathify.plugin],
});
