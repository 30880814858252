import Vue from "vue";
import store from "./store";
import vuexI18n from "vuex-i18n";

const context = require.context("@/i18n/", false, /\.json$/);
var isos = [];
context.keys().map((key) => {
  isos.push({
    obj: context(key),
    key: `${key.replace(".json", "").replace("./", "")}`,
  });
});

const languages = [];
isos.forEach((item) => {
  const { lang = "" } = item.obj;
  if (lang) {
    languages.push({
      key: item.key,
      lang,
    });
  }
});
store.commit("SET_LANGUAGES", languages);

Vue.use(vuexI18n.plugin, store);
isos.forEach((item) => {
  Vue.i18n.add(item.key, item.obj);
});
Vue.use(vuexI18n.plugin, store);
Vue.i18n.set(localStorage.getItem("lang") || "en");
