import api from "@api";
import * as types from "../types";
import { checkMark } from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import { emailRules, required } from "@/utils/rules";
import Vue from "vue";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  mySelf: void 0,
  newPassword: void 0,
  requestForgotPassword: undefined,
  statusRequestForgotPassword: undefined,
  statusRequestForgotPasswordSetNewPassword: undefined,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.user.headers.firstname"),
        value: "firstname",
      },
      {
        text: Vue.i18n.translate("store.modules.user.headers.lastname"),
        value: "lastname",
      },
      {
        text: Vue.i18n.translate("store.modules.user.headers.email"),
        value: "email",
      },
      {
        text: Vue.i18n.translate("store.modules.user.headers.properties"),
        value: "propertyIds.length",
      },
      {
        text: Vue.i18n.translate("active"),
        value: "active",
        overrideValue: ({ active }) => checkMark(active),
      },
      {
        text: "2FA",
        value: "twoFactorAuthenticationEnabled",
        overrideValue: ({ twoFactorAuthenticationEnabled }) =>
          checkMark(twoFactorAuthenticationEnabled),
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },

  propertyRolesHeaders: (state, unused, rootState) => {
    return [
      {
        text: Vue.i18n.translate("store.modules.user.headers.property"),
        value: "propertyId",
        overrideValue: ({ propertyId }) => {
          for (let i = 0; i < rootState.property.items.length; i++) {
            const element = rootState.property.items[i];
            if (element.id === propertyId) {
              return [{ value: element.name }];
            }
          }
        },
      },
      {
        text: Vue.i18n.translate("store.modules.user.headers.roles"),
        value: "roles.length",
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state, unused, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "firstname",
        },
        {
          type: "textfield",
          value: "lastname",
        },
        {
          type: "textfield",
          value: "email",
          rules: [...required, ...emailRules],
        },
        {
          type: "textfield",
          value: "password",
          onlyNewItem: true,
        },
        {
          type: "autocomplete",
          multiple: true,
          chips: true,
          deletableChips: true,
          value: "globalRoles",
          itemValue: "_id",
          itemText: (item) => {
            return item.name;
          },
          items: rootState.role.items,
        },
        {
          type: "slot",
          name: "propertyRoles",
          value: "propertyRoles",
        },
        {
          type: "checkbox",
          value: "active",
        },
        {
          type: "checkbox",
          value: "twoFactorAuthenticationEnabled",
        },
      ],
    });
    return schema;
  },
  propertyRolesSchema: (state, unused, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "propertyAutocomplete",
          value: "propertyId",
          readonlyEditItem: true,
        },
        {
          type: "autocomplete",
          multiple: true,
          chips: true,
          deletableChips: true,
          value: "roles",
          itemValue: "_id",
          itemText: (item) => {
            return item.name;
          },
          items: rootState.role.items,
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexUser({ commit }) {
    commit(types.REQUEST);
    try {
      commit(types.SUCCESS_REQUEST, await api.indexUser());
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async updateSessionUser({ commit }, { item }) {
    commit(types.REQUEST_UPDATE);
    try {
      commit(
        types.SUCCESS_REQUEST_UPDATE,
        await api.updateSessionUser({
          item,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_UPDATE);
    }
  },
  async saveOrUpdateUser({ commit }, { item }) {
    if (item._id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateUser({
            id: item._id,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveUser({
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deleteUser({ commit }, { _id }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteUser({
          _id,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  async changePassword(
    { rootState, dispatch, state },
    { info = rootState.auth.info }
  ) {
    info.password = state.newPassword;
    await dispatch("updateSessionUser", { item: info });
  },
  async forgotPassword({ commit }, { email }) {
    commit("REQUEST_FORGOT_PASSWORD");
    try {
      commit(
        "SUCCESS_REQUEST_FORGOT_PASSWORD",
        await api.userForgotPassword({
          email,
        })
      );
    } catch (e) {
      commit("FAILURE_REQUEST_FORGOT_PASSWORD");
    }
  },
  async forgotPasswordSetNewPassword({ commit }, { code, password }) {
    commit("REQUEST_FORGOT_PASSWORD_SET_NEW_PASSWORD");
    try {
      commit(
        "SUCCESS_REQUEST_FORGOT_PASSWORD_SET_NEW_PASSWORD",
        await api.userForgotPasswordSetNewPassword({
          code,
          password,
        })
      );
    } catch (e) {
      commit("FAILURE_REQUEST_FORGOT_PASSWORD_SET_NEW_PASSWORD");
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state, value) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state, value) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state, value) {
    state.statusDelete = "success";
  },

  REQUEST_FORGOT_PASSWORD(state) {
    state.statusRequestForgotPassword = "fetching";
  },
  SUCCESS_REQUEST_FORGOT_PASSWORD(state) {
    state.statusRequestForgotPassword = "success";
  },
  FAILURE_REQUEST_FORGOT_PASSWORD(state) {
    state.statusRequestForgotPassword = "failure";
  },
  REQUEST_FORGOT_PASSWORD_SET_NEW_PASSWORD(state) {
    state.statusRequestForgotPasswordSetNewPassword = "fetching";
  },
  SUCCESS_REQUEST_FORGOT_PASSWORD_SET_NEW_PASSWORD(state) {
    state.statusRequestForgotPasswordSetNewPassword = "success";
  },
  FAILURE_REQUEST_FORGOT_PASSWORD_SET_NEW_PASSWORD(state) {
    state.statusRequestForgotPasswordSetNewPassword = "failure";
  },
  RESET_FORGOT_PASSWORD_STORE(state) {
    state.statusRequestForgotPassword = undefined;
    state.statusRequestForgotPasswordSetNewPassword = undefined;
  },
  SET_NEW_PASSWORD(state, value) {
    state.newPassword = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
