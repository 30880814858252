import api from "@api/weather";
import * as types from "../../types";
import { checkMark } from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import Vue from "vue";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  searchItems: [],
  search: "",
  statusSearch: void 0,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.weather.location.headers.city"),
        value: "city",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.weather.location.headers.country"
        ),
        value: "country",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.weather.location.headers.lastObservationAt"
        ),
        value: "lastObservationAt",
      },
      {
        text: Vue.i18n.translate("store.modules.weather.location.headers.temp"),
        value: "temp",
      },
      {
        text: Vue.i18n.translate("active"),
        value: "active",
        overrideValue: ({ active }) => checkMark(active),
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state) => {
    const schema = getSchema({
      items: [
        {
          type: "autocomplete",
          value: "items",
          multiple: true,
          deletableChips: true,
          chips: true,
          items: state.items,
          itemValue: "iso1",
          itemText: (item) => {
            return `${item.name} (${item.iso1} ${item.local})`;
          },
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexLocation({ commit }) {
    commit(types.REQUEST);
    try {
      commit(types.SUCCESS_REQUEST, await api.indexLocation());
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveOrUpdateLocation({ commit }, { item }) {
    if (item.id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateLocation({
            id: item.id,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveLocation({
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deleteLocation({ commit }, { id, items = [] }) {
    commit(types.REQUEST_DELETE);
    try {
      if (items.length > 0) {
        commit(
          types.SUCCESS_REQUEST_DELETE,
          await api.bulkDeleteLocation({
            items,
          })
        );
      } else {
        commit(
          types.SUCCESS_REQUEST_DELETE,
          await api.deleteLocation({
            id,
          })
        );
      }
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  async searchLocation({ commit }, { query = "", countryCode = void 0 }) {
    commit(types.REQUEST_SEARCH);
    if (query && query.length > 0) {
      try {
        commit(
          types.SUCCESS_REQUEST_SEARCH,
          await api.searchLocation({
            query,
            countryCode,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SEARCH);
      }
    } else {
      commit(types.SUCCESS_REQUEST_SEARCH, { body: [] });
    }
  },
  async fetchLocation({ commit }, { id }) {
    commit(types.REQUEST_FETCH);
    try {
      commit(
        types.SUCCESS_REQUEST_FETCH,
        await api.fetchLocation({
          id,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_FETCH);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state, value) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state, value) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state, value) {
    state.statusDelete = "success";
  },

  [types.REQUEST_SEARCH](state) {
    state.statusSearch = "fetching";
  },
  [types.FAILURE_REQUEST_SEARCH](state) {
    state.statusSearch = "failure";
  },
  [types.SUCCESS_REQUEST_SEARCH](state, value) {
    state.searchItems = value.body;
    state.statusSearch = "success";
  },

  [types.SET_SEARCH](state, value) {
    state.search = value;
  },

  [types.REQUEST_FETCH](state) {
    state.statusSearch = "fetching";
  },
  [types.FAILURE_REQUEST_FETCH](state) {
    state.statusSearch = "failure";
  },
  [types.SUCCESS_REQUEST_FETCH](state, value) {
    state.searchItems = value.body;
    state.statusSearch = "success";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
