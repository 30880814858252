import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

const Toolbar = () => import("@/components/core/Toolbar");
const Home = () => import("@/views/Home");
const Properties = () => import("@/views/Properties");
const PropertyGroup = () => import("@/views/PropertyGroup");
const Users = () => import("@/views/Users");
const Roles = () => import("@/views/Roles");
const License = () => import("@/views/License");
const PropertyDashboard = () => import("@/views/PropertyDashboard");
const Mediapool = () => import("@/components/Mediapool");
const CheckinRoomManager = () => import("@/views/CheckinRoomManager");
const Roomcontrol = () => import("@/views/oneapp/Roomcontrol");
const Locations = () => import("@/views/weather/Locations");
const Settings = () => import("@/views/weather/Settings");
const ChangePassword = () => import("@/views/ChangePassword");
const LoginForgotPassword = () => import("@/views/LoginForgotPassword");
const LoginForgotPasswordSetNewPassword = () =>
  import("@/views/LoginForgotPasswordSetNewPassword");
const Heartbeat = () => import("@/views/Heartbeat");

import onespeedRoutes from "./onespeed";
import oneappRoutes from "./oneapp";
import onesquareRoutes from "./onesquare";
import onestreamRoutes from "./onestream";
import onekeyRoutes from "./onekey";

const Login = () => import("@/views/Login");
import { BASE_URL } from "../config";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      components: {
        default: Login,
      },
    },
    {
      path: "/login/forgot-password",
      name: "loginForgotPassword",
      components: {
        default: LoginForgotPassword,
      },
    },
    {
      path: "/login/forgot-password/set-new-password",
      name: "loginForgotPasswordSetNewPassword",
      components: {
        default: LoginForgotPasswordSetNewPassword,
      },
    },
    {
      path: "/",
      name: "home",
      components: {
        default: Home,
        toolbar: Toolbar,
      },
    },
    {
      path: "/super/properties",
      name: "properties",
      components: {
        default: Properties,
        toolbar: Toolbar,
      },
    },
    {
      path: "/super/property/group",
      name: "propertyGroup",
      components: {
        default: PropertyGroup,
        toolbar: Toolbar,
      },
    },
    {
      path: "/property/:propertyId/dashboard",
      name: "propertyDashboard",
      components: {
        default: PropertyDashboard,
        toolbar: Toolbar,
      },
    },
    {
      path: "/property/:propertyId/admin/license",
      name: "license",
      components: {
        default: License,
        toolbar: Toolbar,
      },
    },
    {
      path: "/super/users",
      name: "superUsers",
      components: {
        default: Users,
        toolbar: Toolbar,
      },
    },
    {
      path: "/roles",
      name: "roles",
      components: {
        default: Roles,
        toolbar: Toolbar,
      },
    },
    {
      path: "/admin/users",
      name: "propertyUsers",
      components: {
        default: Users,
        toolbar: Toolbar,
      },
    },
    {
      path: "/property/:propertyId/checkin-room-manager",
      name: "checkinRoomManager",
      components: {
        default: CheckinRoomManager,
        toolbar: Toolbar,
      },
    },
    {
      path: "/property/:propertyId/roomcontrol",
      name: "oneappRoomcontrol",
      components: {
        default: Roomcontrol,
        toolbar: Toolbar,
      },
    },
    {
      path: "/property/:propertyId/content-management/mediapool",
      name: "mediapool",
      components: {
        default: Mediapool,
        toolbar: Toolbar,
      },
    },
    {
      path: "/weather/locations",
      name: "locations",
      components: {
        default: Locations,
        toolbar: Toolbar,
      },
    },
    {
      path: "/weather/settings",
      name: "settings",
      components: {
        default: Settings,
        toolbar: Toolbar,
      },
    },
    {
      path: "/profile/change-password",
      name: "changePassword",
      components: {
        default: ChangePassword,
        toolbar: Toolbar,
      },
    },
    {
      path: "/super/heartbeat",
      name: "heartbeat",
      components: {
        default: Heartbeat,
        toolbar: Toolbar,
      },
    },
    ...onespeedRoutes,
    ...oneappRoutes,
    ...onesquareRoutes,
    ...onestreamRoutes,
    ...onekeyRoutes,
  ],
});

router.beforeEach(async (to, from, next) => {
  if (
    to.name === "login" ||
    to.name === "loginForgotPassword" ||
    to.name === "loginForgotPasswordSetNewPassword" ||
    to.name === "resetPassword" ||
    to.name === "resetPasswordId"
  ) {
    next();
    return;
  }

  const { accessToken } = store.state.auth;
  if (!accessToken) {
    await store.dispatch("auth/token", {
      nextpage: to.fullPath,
    });
  }

  next();

  /*if (to.meta.product && from.query.propertyId && !to.query.propertyId) {
    to.query.propertyId = from.query.propertyId;
    next({
      path: to.path,
      query: {
        ...to.query,
        propertyId: from.query.propertyId
      },
      params: {
        ...to.params
      }
    });
    return;
  } else if (to.meta.product && !to.query.propertyId) {
    router.app.$nextTick(() => {
      router.app.$store.commit(SET_PROJECT_DIALOG, true);
    });
  } else if (!to.meta.product && to.query.propertyId) {
    next({
      path: to.path,
      query: {
        ...to.query,
        propertyId: void 0
      },
      params: {
        ...to.params
      }
    });
    return;
  }*/
});

export default router;
