import Vue from "vue";
import { API_ROOT_V3 } from "../../config";

export const InfoTextResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onekey/info-text"
);
export const InfoTextIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onekey/info-text/index"
);

export const LanguagePropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onekey/language"
);
export const LanguageIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onekey/language/index"
);
