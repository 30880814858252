<template>
  <v-app :dark="$store.state.dark" :light="!$store.state.dark">
    <v-progress-linear
      class="ma-0"
      color="accent"
      style="position: fixed; z-index: 5"
      :height="3"
      :active="$store.state.loading"
      v-bind:indeterminate="true"
    ></v-progress-linear>
    <navigation-drawer></navigation-drawer>
    <router-view name="toolbar"></router-view>
    <v-main>
      <vue-page-transition style="height: 100%" name="fade-in-down">
        <router-view></router-view>
      </vue-page-transition>
    </v-main>
    <core-footer></core-footer>
    <v-dialog
      :value="
        $route.name &&
        $route.name.length > 0 &&
        $route.name !== 'login' &&
        error === '401'
      "
      persistent
      max-width="320"
    >
      <v-card>
        <v-card-title class="title">{{ error }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-text="$t('unauthorized')" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="resetError" color="primary" text v-text="$t('ok')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// import "@/VuetifyMaterialDashboardFree/styles/overrides.sass";

import CoreFooter from "@/components/core/Footer";
import NavigationDrawer from "@/components/core/NavigationDrawer";
import { RESET } from "@store/types";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    CoreFooter,
    NavigationDrawer,
  },
  created() {
    this.$vuetify.theme.dark = this.$store.state.dark;
  },
  computed: {
    ...mapState({
      error: ({ error }) => error.value,
      properties: ({ property }) => property.items,
    }),
  },
  methods: {
    ...mapActions("property", ["setSelectedProperty"]),
    ...mapMutations("error", {
      resetError: RESET,
    }),
  },
  watch: {
    $route(val) {
      this.setSelectedProperty({ id: val.query.propertyId });
      this.setSelectedProperty({ id: val.params.propertyId });
    },
    properties() {
      this.setSelectedProperty({ id: this.$route.query.propertyId });
      this.setSelectedProperty({ id: this.$route.params.propertyId });
    },
    "$store.state.dark"(val) {
      this.$vuetify.theme.dark = val;
    },
  },
};
</script>

<style lang="scss">
html {
  overflow: auto;
}
</style>
