import Vue from "vue";
import { API_ROOT_V3 } from "../../config";

export const WhitelistResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/whitelist{/id}"
);
export const WhitelistIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/whitelist/index"
);
export const WhitelistPropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/whitelist"
);
export const BlocklistResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/blocklist{/_id}"
);
export const BlocklistIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/blocklist/index"
);
export const BlocklistPropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/blocklist"
);
export const LanguagePropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/language"
);
export const LanguageIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/language/index"
);
export const ThemeResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/theme{/id}"
);
export const ThemeIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/theme/index"
);
export const ThemePropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/theme"
);
export const TermsAndConditionsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/terms"
);
export const TermsAndConditionsIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/terms/index"
);
export const SettingsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/settings"
);
export const SettingsSchema = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/settings/schema"
);
export const TicketResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/ticket{/id}"
);
export const TicketPropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/ticket"
);
export const TicketIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/ticket/index"
);

export const BulkTicketResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/bulkticket"
);
export const BulkTicketIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/bulkticket/index"
);
export const BulkTicketDeleteResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/bulkticket{/ticketId}"
);
export const BulkTicketCSVResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/bulkticket{/ticketId}/csv"
);
export const BulkTicketPDFResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/bulkticket{/ticketId}/pdf"
);
export const BulkTicketEmailResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/bulkticket{/ticketId}/email"
);
export const BulkTicketRoomsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/bulkticket/rooms"
);

export const LoginTypesResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/logintypes"
);
export const LoginTypesIndexResource = Vue.resource(
  API_ROOT_V3 + "onespeed/logintypes/index"
);

export const MonitoringIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/profile/index"
);

export const MonitoringExportResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/profile/csv"
);

export const SaveProfileResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/profile"
);
export const ProfileResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/profile{/id}"
);
export const BandwidthStats = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/stats/bandwidth"
);
export const SessionStats = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/stats/sessions"
);
export const BandwidthStatsNow = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/stats/bandwidth/now"
);
export const SessionStatsNow = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/stats/sessions/now"
);
export const DisconnectDeviceResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/send-disconnect-radius"
);
export const RevenueStats = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/stats/revenue"
);

export const TopSessionsStats = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/stats/sessions/top"
);

export const TopSessionsStatsCSV = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/stats/sessions/top/csv"
);
