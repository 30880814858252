import api from "@api/oneapp";
import router from "@/router";

const state = {
  porReportValues: [],
  porTotal: 0,
  statusPorReport: void 0,
};

const actions = {
  async getPorReportSummary(
    { commit },
    { propertyId = router.currentRoute.params.propertyId, month }
  ) {
    commit("FETCHING_PORS");
    try {
      const response = await api.getPorReportSummary({
        propertyId,
        month,
      });
      commit("SUCCESS_FETCHING_PORS", {
        body: response.body,
      });
    } catch (e) {
      commit("FAILURE_FETCHING_PORS");
    }
  },
};

const mutations = {
  FETCHING_PORS(state) {
    state.statusPorReport = "fetching";
  },
  SUCCESS_FETCHING_PORS(state, { body = { items: [] } }) {
    const items = body.items;
    state.porTotal = 0;

    state.porReportValues = items.map((item) => {
      state.porTotal += item.roomCount;
      return [item.date, item.roomCount];
    });
    state.statusPorReport = "success";
  },
  FAILURE_FETCHING_PORS(state) {
    state.statusPorReport = "failure";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
