import api from "@api";
import * as types from "../../types";

const state = {
  items: {},
  status: void 0,
  itemById: {},
  statusById: void 0,
  statusSaveOrUpdate: void 0,
  statusDelete: void 0,
};

// actions
const actions = {
  async getONEappLicense({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.getONEappLicensesByPropertyId({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveOrUpdateONEappLicense({ commit }, { item, propertyId }) {
    commit(types.REQUEST_SAVE_OR_UPDATE);
    try {
      commit(
        types.SUCCESS_REQUEST_SAVE_OR_UPDATE,
        await api.saveOrUpdateONEappLicense({
          propertyId,
          item,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_SAVE_OR_UPDATE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = { info: { autoRenewDays: 365, autoRenew: true, themes: 5 } };
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body;
  },

  [types.REQUEST_SAVE_OR_UPDATE](state) {
    state.statusSaveOrUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE_OR_UPDATE](state) {
    state.statusSaveOrUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE_OR_UPDATE](state, value) {
    state.statusSaveOrUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state, value) {
    state.statusDelete = "success";
  },

  [types.REQUEST_BY_ID](state) {
    state.statusById = "fetching";
  },
  [types.FAILURE_REQUEST_BY_ID](state) {
    state.statusById = "failure";
  },
  [types.SUCCESS_REQUEST_BY_ID](state, value) {
    state.statusById = "success";
    state.itemById = value.body;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
