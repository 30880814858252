import api from "@api/oneapp";
import router from "@/router";
import { commit } from "vuex-pathify";

const state = {
  statusAuthPayment: void 0,
};

const actions = {
  async releaseAuthPayment(
    { commit },
    { propertyId = router.currentRoute.params.propertyId, authorizationId }
  ) {
    commit("FETCHING_AUTH");
    try {
      await api.releaseAuthPayment({
        propertyId,
        authorizationId,
      });
      commit("SUCCESS_FETCHING_AUTH");
    } catch (e) {
      commit("FAILURE_FETCHING_AUTH");
    }
  },
};

const mutations = {
  FETCHING_AUTH(state) {
    state.statusAuthPayment = "fetching";
  },
  SUCCESS_FETCHING_AUTH(state) {
    state.statusAuthPayment = "success";
  },
  FAILURE_FETCHING_AUTH(state) {
    state.statusAuthPayment = "failure";
  },
  RESET_AUTH(state) {
    state.statusAuthPayment = void 0;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
