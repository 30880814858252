import api from "@api/oneapp";
import * as types from "../../types";
import { getSchema } from "@/utils/schema";
import { imageOverride } from "@/utils/datatable-overrides";
import Vue from "vue";
import set from "lodash/set";
import { ipRules } from "../../../utils/rules";

const state = {
  roomcontrol: {},
  error: void 0,
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  snackbar: false,
};

const getters = {
  cameraHeaders: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.roomcontrol.headers.room"),
        value: "room",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.roomcontrol.headers.automationId"
        ),
        value: "automationId",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.roomcontrol.headers.cameraAddress"
        ),
        value: "cameraAddress",
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  cameraItemSchema: (state, unused, rootState) => {
    return getSchema({
      items: [
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.id"),
          type: "textfield",
          readonly: true,
          value: "cameraId",
          rules: [],
        },
        {
          type: "textfield",
          value: "room",
          rules: [],
          defaultValue: "",
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.room"),
        },
        {
          type: "textfield",
          value: "automationId",
          rules: [],
          defaultValue: "",
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.automationId"
          ),
        },
        {
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.cameraDescription"
          ),
          type: "textfield",
          value: "description",
          multiLanguage: true,
        },
        {
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.cameraAddress"
          ),
          type: "textfield",
          rules: [...ipRules],
          value: "cameraAddress",
        },
      ],
      languages: rootState.oneappLanguage.items,
    });
  },
  assignmentHeaders: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.roomcontrol.headers.room"),
        value: "room",
      },
      {
        text: Vue.i18n.translate("store.modules.roomcontrol.headers.targetId"),
        value: "targetId",
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  assignmentItemSchema: (state) => {
    const commandNames = (state.roomcontrol.command || []).map((el) => el.name);
    return getSchema({
      items: [
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.id"),
          type: "textfield",
          readonly: true,
          value: "assignmentId",
          rules: [],
        },
        {
          type: "textfield",
          value: "room",
          rules: [],
          defaultValue: "",
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.room"),
        },
        {
          type: "autocomplete",
          value: "assignedCommandNames",
          multiple: true,
          deletableChips: true,
          chips: true,
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.assignedCommands"
          ),
          itemValue: [],
          rules: [],
          items: commandNames,
        },
        {
          type: "textfield",
          value: "targetId",
          rules: [],
          defaultValue: "",
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.targetId"
          ),
        },
      ],
    });
  },
  commandHeaders: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.roomcontrol.headers.name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate("theme.logoImage"),
        value: "iconImage",
        overrideValue: (item) => {
          return imageOverride(item.iconImage);
        },
      },
      {
        text: Vue.i18n.translate(
          "store.modules.roomcontrol.headers.actionType"
        ),
        value: "actionType",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.roomcontrol.headers.targetOptions"
        ),
        value: "targetOptions",
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  commandItemSchema: (state, unused, rootState) => {
    return getSchema({
      items: [
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.id"),
          type: "textfield",
          readonly: true,
          value: "commandId",
          rules: [],
        },
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.name"),
          type: "textfield",
          value: "name",
        },
        {
          type: "textfield",
          value: "title",
          multiLanguage: true,
          rules: [],
        },
        {
          type: "textfield",
          value: "description",
          multiLanguage: true,
          rules: [],
        },
        {
          type: "textfield",
          value: "onValue",
          multiLanguage: true,
          rules: [],
        },
        {
          type: "textfield",
          value: "offValue",
          multiLanguage: true,
          rules: [],
        },
        {
          type: "autocomplete",
          value: "actionType",
          multiple: false,
          deletableChips: false,
          chips: false,
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.actionType"
          ),
          items: ["button", "toggle-button", "slider"],
          rules: [],
        },
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.unit"),
          type: "textfield",
          rules: [],
          value: "unit",
        },
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.min"),
          type: "textfield",
          value: "min",
          valueType: "number",
          rules: [],
        },
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.max"),
          type: "textfield",
          value: "max",
          valueType: "number",
          rules: [],
        },
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.step"),
          type: "textfield",
          value: "step",
          valueType: "number",
          rules: [],
        },
        {
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.targetId"
          ),
          type: "textfield",
          rules: [],
          value: "targetId",
        },
        {
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.targetOptions"
          ),
          type: "textfield",
          value: "targetOptions",
        },
        {
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.valueModifier"
          ),
          type: "textfield",
          rules: [],
          value: "valueModifier",
        },
        {
          type: "media",
          value: "iconImage",
          label: Vue.i18n.translate("theme.logoImage"),
        },
      ],
      languages: rootState.oneappLanguage.items,
    });
  },
  groupHeaders: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.roomcontrol.headers.name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate("store.modules.roomcontrol.headers.sort"),
        value: "sort",
      },
      {
        text: Vue.i18n.translate("store.modules.roomcontrol.headers.parent"),
        value: "parentGroupName",
      },
      {
        text: Vue.i18n.translate("theme.logoImage"),
        value: "iconImage",
        overrideValue: (item) => {
          return imageOverride(item.iconImage);
        },
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  groupItemSchema: (state, unused, rootState) => {
    const groupNames = (state.roomcontrol.group || []).map((el) => el.name);
    const commandNames = (state.roomcontrol.command || []).map((el) => el.name);
    return getSchema({
      items: [
        {
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.id"),
          type: "textfield",
          readonly: true,
          value: "groupId",
          rules: [],
        },
        {
          type: "textfield",
          value: "sort",
          valueType: "number",
          rules: [],
          defaultValue: 0,
          label: "sort",
        },
        {
          type: "textfield",
          value: "name",
          rules: [],
          defaultValue: "",
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.name"),
        },
        {
          type: "textfield",
          value: "title",
          multiLanguage: true,
          rules: [],
        },
        {
          type: "textfield",
          value: "description",
          multiLanguage: true,
          rules: [],
        },
        {
          type: "autocomplete",
          value: "parentGroupName",
          multiple: false,
          deletableChips: true,
          chips: true,
          label: Vue.i18n.translate("store.modules.roomcontrol.headers.parent"),
          itemValue: [],
          rules: [],
          items: groupNames,
        },
        {
          type: "autocomplete",
          value: "assignedCommandNames",
          multiple: true,
          deletableChips: true,
          chips: true,
          label: Vue.i18n.translate(
            "store.modules.roomcontrol.headers.assignedCommands"
          ),
          itemValue: [],
          rules: [],
          items: commandNames,
        },
        {
          type: "media",
          value: "iconImage",
          label: Vue.i18n.translate("theme.logoImage"),
        },
      ],
      languages: rootState.oneappLanguage.items,
    });
  },
  settingSchema: () => [
    {
      key: "targetId",
      title: "TargetId",
      description:
        "Default controller identification. TargetId order: command > assignment > setting",
      type: "string",
      multiSelect: false,
      defaultValue: "",
    },
    {
      key: "type",
      title: "System",
      description: "Connected roomcontrol system",
      type: "string",
      multiSelect: false,
      defaultValue: "",
      options: ["bacnet", "knx", "eibport", "loxone", "lutron"],
    },
  ],
  camera: (state) => {
    return (state.roomcontrol.camera || []).map((el, index) => ({
      ...el,
      id: index,
    }));
  },
  assignment: (state) => {
    const cmdMap = (state.roomcontrol.command || []).reduce((acc, el) => {
      acc[el.commandId] = el.name;
      return acc;
    }, {});
    return (state.roomcontrol.assignment || []).map((el, index) => ({
      ...el,
      id: index,
      assignedCommandNames: el.assignedCommandIds.map((el) => cmdMap[el]),
    }));
  },
  command: (state) => {
    return (state.roomcontrol.command || []).map((el, index) => ({
      ...el,
      id: index,
    }));
  },
  group: (state) => {
    const cmdMap = (state.roomcontrol.command || []).reduce((acc, el) => {
      acc[el.commandId] = el.name;
      return acc;
    }, {});
    const groupMap = (state.roomcontrol.group || []).reduce((acc, el) => {
      acc[el.groupId] = el.name;
      return acc;
    }, {});
    return (state.roomcontrol.group || []).map((el, index) => ({
      ...el,
      id: index,
      parentGroupName: groupMap[el.parentGroupId] || "",
      assignedCommandNames: el.assignedCommandIds.map((el) => cmdMap[el]),
    }));
  },
  setting: (state) => state.roomcontrol.setting,
};

// actions
const actions = {
  async indexRoomcontrol({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexRoomcontrol({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async updateRoomcontrol({ commit }, { item, propertyId }) {
    commit(types.REQUEST_UPDATE);
    try {
      commit(
        types.SUCCESS_REQUEST_UPDATE,
        await api.updateRoomcontrol({
          propertyId,
          item,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_UPDATE);
    }
  },
  updateSettingKeyValue({ commit }, { key, value }) {
    commit(types.UPDATE_KEY_VALUE, { key, value });
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.roomcontrol = {};
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.roomcontrol = value.body;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state, value) {
    state.statusSave = "failure";
    state.error = value;
  },
  [types.SET_SNACKBAR](state, value) {
    state.snackbar = value;
  },
  [types.SUCCESS_REQUEST_SAVE](state) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusDelete = "success";
  },
  [types.UPDATE_KEY_VALUE](state, { key, value }) {
    set(state.roomcontrol.setting, key, value);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
