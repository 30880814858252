import { required } from "@utils/rules";
const requiredRule = required;
import startCase from "lodash/startCase";

export const textField = ({
  value,
  label = startCase(value),
  required = true,
  rules = [...requiredRule],
  multiLanguage = false,
  select = undefined,
  onlyNewItem = false,
  valueType = "text",
  readonly = false,
  prefix = undefined,
  suffix = undefined,
  permission,
  appendOuterIcon = "",
  externalLink = "",
  hint = "",
  persistentHint = false,
}) => ({
  type: "textfield",
  label,
  value,
  required,
  rules,
  multiLanguage,
  select,
  onlyNewItem,
  valueType: valueType,
  readonly,
  prefix,
  suffix,
  permission,
  appendOuterIcon,
  externalLink,
  hint,
  persistentHint,
});

const textArea = ({
  value,
  label = startCase(value),
  rules = [...requiredRule],
  multiLanguage = false,
  onlyNewItem = false,
  valueType = "text",
  readonly = false,
  prefix = undefined,
  suffix = undefined,
  permission,
}) => ({
  type: "textarea",
  label,
  value,
  rules,
  multiLanguage,
  onlyNewItem,
  valueType: valueType,
  readonly,
  prefix,
  suffix,
  permission,
});

const autocomplete = ({
  value,
  label = startCase(value),
  required = true,
  rules = [...requiredRule],
  items = [],
  multiple = false,
  deletableChips = false,
  chips = false,
  readonly = false,
  itemText = (item) => {
    return item.text;
  },
  itemValue = "value",
  permission,
}) => ({
  type: "autocomplete",
  label,
  value,
  required,
  rules,
  items,
  multiple,
  deletableChips,
  chips,
  itemValue,
  itemText,
  readonly,
  permission,
});

const checkbox = ({
  value,
  label = startCase(value),
  required = false,
  rules = [],
  permission,
  readonly,
}) => ({
  type: "checkbox",
  label,
  value,
  required,
  rules,
  permission,
  readonly,
});

export const getSchema = ({ items, languages = [] }) => {
  const schema = { items: [], languages };
  items.forEach((item) => {
    switch (item.type) {
      case "textfield":
        schema.items.push(textField(item));
        break;
      case "textarea":
        schema.items.push(textArea(item));
        break;
      case "autocomplete":
        schema.items.push(autocomplete(item));
        break;
      case "checkbox":
        schema.items.push(checkbox(item));
        break;
      default:
        schema.items.push(item);
    }
  });

  return schema;
};
