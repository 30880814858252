import * as types from "../types";

const state = {
  value: void 0,
};

// actions
const actions = {
  //
};

const mutations = {
  [types.RESET](state) {
    state.value = void 0;
  },
  [types.ERROR_403](state) {
    state.value = "403";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
