import {
  GuestResource,
  GuestPropertyResource,
  GuestIndexResource,
  GuestPropertyPingResource,
  GuestPropertySyncResource,
} from "./resources";

export default {
  indexGuest({ propertyId }) {
    return GuestIndexResource.get({
      propertyId,
    });
  },
  updateGuest({ propertyId, _id, item }) {
    return GuestResource.update(
      {
        propertyId,
        _id,
      },
      item
    );
  },
  saveGuest({ item, propertyId }) {
    return GuestPropertyResource.save(
      {
        propertyId,
      },
      item
    );
  },
  deleteGuest({ _id, propertyId }) {
    return GuestResource.delete({
      _id,
      propertyId,
    });
  },
  pingInterface({ propertyId }) {
    return GuestPropertyPingResource.get({
      propertyId,
    });
  },
  syncPMS({ propertyId }) {
    return GuestPropertySyncResource.get({
      propertyId,
    });
  },
};
