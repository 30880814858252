import api from "@api/oneapp";
import * as types from "../../types";
import { imageOverride } from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import Vue from "vue";
import router from "@/router";

const state = {
  items: [],
  error: void 0,
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  snackbar: false,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("oneapp.enhancements.position"),
        value: "pos",
        sortable: true,
        align: "center",
      },
      {
        text: Vue.i18n.translate("oneapp.enhancements.title"),
        value: "title",
        overrideValue: ({ i18n = [{ title: "" }] }) => {
          return [{ value: i18n[0].title }];
        },
      },
      {
        text: Vue.i18n.translate("oneapp.enhancements.description"),
        value: "description",
        overrideValue: ({ i18n = [{ description: "" }] }) => {
          return [{ value: i18n[0].description }];
        },
      },
      {
        text: Vue.i18n.translate("oneapp.enhancements.price"),
        value: "price",
      },
      {
        text: Vue.i18n.translate("oneapp.enhancements.imageId"),
        value: "image",
        overrideValue: ({ image }) => imageOverride(image),
      },
      {
        text: Vue.i18n.translate("oneapp.enhancements.vendorData"),
        value: "vendorData",
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state, unused, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "title",
          label: Vue.i18n.translate("oneapp.enhancements.title"),
          multiLanguage: true,
        },
        {
          type: "texteditor",
          value: "description",
          label: Vue.i18n.translate("oneapp.enhancements.description"),
          multiLanguage: true,
        },
        {
          type: "textfield",
          value: "price",
          label: Vue.i18n.translate("oneapp.enhancements.price"),
          hint: Vue.i18n.translate("oneapp.enhancements.priceHint"),
          persistentHint: true,
        },
        {
          type: "textfield",
          value: "pos",
          required: false,
          rules: [
            (v) =>
              v === "" ||
              v > 0 ||
              Vue.i18n.translate("oneapp.enhancements.pos_rule"),
          ],
          label: Vue.i18n.translate("oneapp.enhancements.position"),
        },
        {
          type: "media",
          value: "image",
          label: Vue.i18n.translate("oneapp.enhancements.imageId"),
        },
        {
          type: "textfield",
          value: "vendorData",
          appendOuterIcon: "mdi-help-circle-outline",
          externalLink: "https://at-visions.atlassian.net/l/cp/yjvPus2A",
          label: Vue.i18n.translate("oneapp.enhancements.vendorData"),
        },
      ],
      languages: rootState.oneappLanguage.items,
    });
    return schema;
  },
};

// actions
const actions = {
  async indexEnhancements(
    { commit },
    { propertyId = router.currentRoute.params.propertyId }
  ) {
    commit(types.REQUEST);
    try {
      let data = await api.indexEnhancements({
        propertyId,
      });

      if (data.body.items) {
        data.body.items = data.body.items.map((i) => {
          if (i.pos === undefined || (!i.pos && i.pos !== 0)) {
            i.pos = "";
          }

          return i;
        });
      }

      commit(types.SUCCESS_REQUEST, data);
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  saveOrUpdateEnhancements: async function (
    { state, commit },
    { item, propertyId = router.currentRoute.params.propertyId }
  ) {
    if (item._id) {
      commit(types.REQUEST_UPDATE);
      try {
        const res = await api.updateEnhancements({
          _id: item._id,
          propertyId,
          item,
        });

        if (!res.body) {
          commit(types.FAILURE_REQUEST_UPDATE);
          return;
        }

        for (let it = 0; it < state.items.length; it++) {
          if (state.items[it]._id === item._id) {
            state.items[it] = item;
            break;
          }
        }

        commit(types.SUCCESS_REQUEST_UPDATE);
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        const res = await api.saveEnhancements({
          item,
          propertyId,
        });

        if (!res.body) {
          commit(types.FAILURE_REQUEST_SAVE);
          return;
        }

        commit(types.SUCCESS_REQUEST_SAVE, res.body);
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE, e);
        commit(types.SET_SNACKBAR, true);
      }
    }
  },
  async deleteEnhancements(
    { state, commit },
    { _id, propertyId = router.currentRoute.params.propertyId }
  ) {
    commit(types.REQUEST_DELETE);
    try {
      const res = await api.deleteEnhancements({
        _id,
        propertyId,
      });

      if (!res.body) {
        commit(types.FAILURE_REQUEST_DELETE);
        return;
      }

      const idx = state.items.findIndex((i) => i._id === _id);
      state.items.splice(idx, 1);

      commit(types.SUCCESS_REQUEST_DELETE);
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  async bulkDeleteEnhancements(
    { commit },
    { propertyId = router.currentRoute.params.propertyId, items }
  ) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.bulkDeleteEnhancements({
          items,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state, value) {
    state.statusSave = "failure";
    state.error = value;
  },
  [types.SET_SNACKBAR](state, value) {
    state.snackbar = value;
  },
  [types.SUCCESS_REQUEST_SAVE](state, item) {
    state.statusSave = "success";
    state.items.push(item);
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusDelete = "success";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
