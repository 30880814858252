import {
  PropertiesOnestreamLicenseResource,
  DeviceIndexResource,
  DeviceResource,
  DevicePropertyResource,
  SettingsResource,
  SettingsSchema,
  StatsIndexResource,
  ServiceMappingsResource,
  ServiceUsageResource,
} from "./resources";

/*
  get: {method: 'GET'},
  save: {method: 'POST'},
  query: {method: 'GET'},
  update: {method: 'PUT'},
  remove: {method: 'DELETE'},
  delete: {method: 'DELETE'}
*/

export default {
  getOnestreamLicensesByPropertyId({ propertyId }) {
    return PropertiesOnestreamLicenseResource.get({
      propertyId,
    });
  },
  saveOrUpdateOnestreamLicense({ propertyId, item }) {
    return PropertiesOnestreamLicenseResource.update(
      {
        propertyId,
      },
      {
        ...item,
      }
    );
  },
  indexServiceUsage({ propertyId }) {
    return ServiceUsageResource.get({
      propertyId,
    });
  },
  indexDevice({ propertyId }) {
    return DeviceIndexResource.get({
      propertyId,
    });
  },
  updateDevice({ id, propertyId, item }) {
    return DeviceResource.update(
      {
        id,
        propertyId,
      },
      {
        ...item,
      }
    );
  },
  saveDevice({ item, propertyId }) {
    return DevicePropertyResource.save(
      {
        propertyId,
      },
      {
        ...item,
      }
    );
  },
  deleteDevice({ id, propertyId }) {
    return DeviceResource.delete({
      id,
      propertyId,
    });
  },

  getSettings({ propertyId }) {
    return SettingsResource.get({ propertyId });
  },
  getSchema({ propertyId }) {
    return SettingsSchema.get({ propertyId });
  },
  updateSettings({ items, propertyId }) {
    return SettingsResource.update({ propertyId }, { ...items });
  },
  indexStats({ propertyId }) {
    return StatsIndexResource.get({
      propertyId,
    });
  },
  indexServiceMappings({ propertyId }) {
    return ServiceMappingsResource.get({ propertyId });
  },
  saveMapping({ item, propertyId }) {
    return ServiceMappingsResource.save({ propertyId }, { ...item });
  },
  deleteMapping({ id, propertyId }) {
    return ServiceMappingsResource.delete({ propertyId, id });
  },
  updateMapping({ id, item, propertyId }) {
    return ServiceMappingsResource.update(
      {
        propertyId,
        id,
      },
      {
        ...item,
      }
    );
  },
};
