import api from "@api";
import * as types from "../types";
import { getSchema } from "@/utils/schema";
import Vue from "vue";

const state = {
  items: [],
  activeItems: [],
  status: void 0,
  itemById: {},
  statusById: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  statusActiveById: void 0,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state, unused, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "name",
        },
        {
          type: "autocomplete",
          multiple: true,
          chips: true,
          deletableChips: true,
          value: "properties",
          itemText: (item) => {
            return item.name;
          },
          itemValue: "id",
          items: rootState.property.items,
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexPropertyGroup({ commit }) {
    commit(types.REQUEST);
    try {
      commit(types.SUCCESS_REQUEST, await api.indexPropertyGroup());
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async getPropertyGroupById({ commit }, { _id }) {
    commit(types.REQUEST_BY_ID);
    try {
      commit(
        types.SUCCESS_REQUEST_BY_ID,
        await api.getPropertyGroupById({
          _id,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_BY_ID);
    }
  },
  async getActiveGroupById({ commit }, { propertyId }) {
    commit(types.REQUEST_ACTIVE_GROUP);
    try {
      commit(
        types.SUCCESS_REQUEST_ACTIVE_GROUP,
        await api.getActiveGroupById({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_ACTIVE_GROUP);
    }
  },
  async saveOrUpdatePropertyGroup({ commit }, { item }) {
    if (item._id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updatePropertyGroup({
            _id: item._id,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.savePropertyGroup({
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deletePropertyGroup({ commit }, { _id }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deletePropertyGroup({
          _id,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    const { body = [] } = value;
    state.status = "success";
    state.items = body;
  },
  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state, value) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state, value) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state, value) {
    state.statusDelete = "success";
  },

  [types.REQUEST_BY_ID](state) {
    state.statusById = "fetching";
  },
  [types.FAILURE_REQUEST_BY_ID](state) {
    state.statusById = "failure";
  },
  [types.SUCCESS_REQUEST_BY_ID](state, value) {
    state.statusById = "success";
    state.itemById = value.body;
  },
  [types.REQUEST_ACTIVE_GROUP](state) {
    state.statusActiveById = "fetching";
  },
  [types.FAILURE_REQUEST_ACTIVE_GROUP](state) {
    state.statusActiveById = "failure";
  },
  [types.SUCCESS_REQUEST_ACTIVE_GROUP](state, value) {
    const { body = [] } = value;
    state.statusActiveById = "success";
    state.activeItems = body;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
