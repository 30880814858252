export const RESET = "RESET";
export const REQUEST = "REQUEST";
export const FAILURE_REQUEST = "FAILURE_REQUEST";
export const SUCCESS_REQUEST = "SUCCESS_REQUEST";

export const REQUEST_SAVE = "REQUEST_SAVE";
export const FAILURE_REQUEST_SAVE = "FAILURE_REQUEST_SAVE";
export const SUCCESS_REQUEST_SAVE = "SUCCESS_REQUEST_SAVE";

export const REQUEST_UPDATE = "REQUEST_UPDATE";
export const FAILURE_REQUEST_UPDATE = "FAILURE_REQUEST_UPDATE";
export const SUCCESS_REQUEST_UPDATE = "SUCCESS_REQUEST_UPDATE";

export const REQUEST_PROFILE_EXPORT = "REQUEST_PROFILE_EXPORT";
export const FAILURE_REQUEST_PROFILE_EXPORT = "FAILURE_REQUEST_PROFILE_EXPORT";
export const SUCCESS_REQUEST_PROFILE_EXPORT = "SUCCESS_REQUEST_PROFILE_EXPORT";

export const REQUEST_DELETE = "REQUEST_DELETE";
export const FAILURE_REQUEST_DELETE = "FAILURE_REQUEST_DELETE";
export const SUCCESS_REQUEST_DELETE = "SUCCESS_REQUEST_DELETE";

export const REQUEST_BY_ID = "REQUEST_BY_ID";
export const FAILURE_REQUEST_BY_ID = "FAILURE_REQUEST_BY_ID";
export const SUCCESS_REQUEST_BY_ID = "SUCCESS_REQUEST_BY_ID";

export const REQUEST_ACTIVE_GROUP = "REQUEST_ACTIVE_GROUP";
export const FAILURE_REQUEST_ACTIVE_GROUP = "FAILURE_REQUEST_ACTIVE_GROUP";
export const SUCCESS_REQUEST_ACTIVE_GROUP = "SUCCESS_REQUEST_ACTIVE_GROUP";

export const REQUEST_SAVE_OR_UPDATE = "REQUEST_SAVE_OR_UPDATE";
export const FAILURE_REQUEST_SAVE_OR_UPDATE = "FAILURE_REQUEST_SAVE_OR_UPDATE";
export const SUCCESS_REQUEST_SAVE_OR_UPDATE = "SUCCESS_REQUEST_SAVE_OR_UPDATE";

export const SET_DRAWER = "SET_DRAWER";
export const SET_PROJECT_DRAWER = "SET_PROJECT_DRAWER";
export const SET_LOADING = "SET_LOADING";
export const SET_PROJECT_DIALOG = "SET_PROJECT_DIALOG";
export const SET_DARK = "SET_DARK";

export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";

export const UPDATE_KEY_VALUE = "UPDATE_KEY_VALUE";

export const ERROR_403 = "ERROR_403";
export const ERROR_401 = "ERROR_401";

export const SET_RECENTLY_SELECTED_PROJECTS = "SET_RECENTLY_SELECTED_PROJECTS";

export const REQUEST_SEARCH = "REQUEST_SEARCH";
export const SUCCESS_REQUEST_SEARCH = "SUCCESS_REQUEST_SEARCH";
export const FAILURE_REQUEST_SEARCH = "FAILURE_REQUEST_SEARCH";

export const SET_SEARCH = "SET_SEARCH";

export const REQUEST_FETCH = "REQUEST_FETCH";
export const SUCCESS_REQUEST_FETCH = "SUCCESS_REQUEST_FETCH";
export const FAILURE_REQUEST_FETCH = "FAILURE_REQUEST_FETCH";

export const SET_SNACKBAR = "SET_SNACKBAR";

export const SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE";

export const REQUEST_BULK_TICKETS = "REQUEST_BULK_TICKETS";
export const FAILURE_REQUEST_BULK_TICKETS = "FAILURE_REQUEST_BULK_TICKETS";
export const SUCCESS_REQUEST_BULK_TICKETS = "SUCCESS_REQUEST_BULK_TICKETS";

export const REQUEST_ROOMS_BULK_TICKETS = "REQUEST_ROOMS_BULK_TICKETS";
export const FAILURE_REQUEST_ROOMS_BULK_TICKETS =
  "FAILURE_REQUEST_ROOMS_BULK_TICKETS";
export const SUCCESS_REQUEST_ROOMS_BULK_TICKETS =
  "SUCCESS_REQUEST_ROOMS_BULK_TICKETS";

export const REQUEST_BULK_CREATE = "REQUEST_BULK_CREATE";
export const SUCCESS_BULK_CREATE = "SUCCESS_BULK_CREATE";
export const FAILURE_BULK_CREATE = "FAILURE_BULK_CREATE";

export const REQUEST_BULK_DELETE = "REQUEST_BULK_DELETE";
export const SUCCESS_BULK_DELETE = "SUCCESS_BULK_DELETE";
export const FAILURE_BULK_DELETE = "FAILURE_BULK_DELETE";

export const REQUEST_BULK_CSV = "REQUEST_BULK_CSV";
export const SUCCESS_REQUEST_BULK_CSV = "SUCCESS_REQUEST_BULK_CSV";
export const FAILURE_REQUEST_BULK_CSV = "FAILURE_REQUEST_BULK_CSV";

export const REQUEST_BULKTICKET_PDF = "REQUEST_BULKTICKET_PDF";
export const SUCCESS_REQUEST_BULKTICKET_PDF = "SUCCESS_REQUEST_BULKTICKET_PDF";
export const FAILURE_REQUEST_BULKTICKET_PDF = "FAILURE_REQUEST_BULKTICKET_PDF";

export const REQUEST_SEND_BULKTICKET_EMAIL = "REQUEST_SEND_BULKTICKET_EMAIL";
export const SUCCESS_REQUEST_SEND_BULKTICKET_EMAIL =
  "SUCCESS_REQUEST_SEND_BULKTICKET_EMAIL";
export const FAILURE_REQUEST_SEND_BULKTICKET_EMAIL =
  "FAILURE_REQUEST_SEND_BULKTICKET_EMAIL";

export const SUCCESS_REQUEST_PROFILE = "SUCCESS_REQUEST_PROFILE";
export const FAILURE_REQUEST_PROFILE = "FAILURE_REQUEST_PROFILE";
export const REQUEST_PROFILE = "REQUEST_PROFILE";

export const SUCCESS_REQUEST_PING = "SUCCESS_REQUEST_PING";
export const FAILURE_REQUEST_PING = "FAILURE_REQUEST_PING";
export const REQUEST_PING = "REQUEST_PING";

export const SUCCESS_REQUEST_SYNC = "SUCCESS_REQUEST_SYNC";
export const FAILURE_REQUEST_SYNC = "FAILURE_REQUEST_SYNC";
export const REQUEST_SYNC = "REQUEST_SYNC";

export const SUCCESS_REQUEST_DISCONNECT_DEVICES =
  "SUCCESS_REQUEST_DISCONNECT_DEVICES";
export const FAILURE_REQUEST_DISCONNECT_DEVICES =
  "FAILURE_REQUEST_DISCONNECT_DEVICES";
export const REQUEST_DISCONNECT_DEVICES = "REQUEST_DISCONNECT_DEVICES";

export const REQUEST_CONFIRMATION_EMAIL_TEMPLATES =
  "REQUEST_CONFIRMATION_EMAIL_TEMPLATES";
export const SUCCESS_REQUEST_CONFIRMATION_EMAIL_TEMPLATES =
  "SUCCESS_CONFIRMATION_EMAIL_TEMPLATES";
export const FAILURE_REQUEST_CONFIRMATION_EMAIL_TEMPLATES =
  "FAILURE_CONFIRMATION_EMAIL_TEMPLATES";

export const FETCH_RESERVATIONS = "FETCH_RESERVATIONS";
export const SUCCESS_FETCH_RESERVATIONS = "SUCCESS_FETCH_RESERVATIONS";
export const FAILURE_FETCH_RESERVATIONS = "FAILURE_FETCH_RESERVATIONS";

export const FETCH_HEARTBEAT = "FETCH_HEARTBEAT";
export const SUCCESS_FETCH_HEARTBEAT = "SUCCESS_FETCH_HEARTBEAT";
export const FAILURE_FETCH_HEARTBEAT = "FAILURE_FETCH_HEARTBEAT";
export const REQUEST_REFRESH = "REQUEST_REFRESH";
export const SUCCESS_REQUEST_REFRESH = "SUCCESS_REQUEST_REFRESH";
export const FAILURE_REQUEST_REFRESH = "FAILURE_REQUEST_REFRESH";

export const FETCH_RESERVATION = "FETCH_RESERVATION";
export const SUCCESS_FETCH_RESERVATION = "SUCCESS_FETCH_RESERVATION";
export const FAILURE_FETCH_RESERVATION = "FAILURE_FETCH_RESERVATION";
export const SET_INFO = "SET_INFO";
export const CHECKIN = "CHECKIN";
export const SUCCESS_CHECKIN = "SUCCESS_CHECKIN";
export const FAILURE_CHECKIN = "FAILURE_CHECKIN";
export const UPDATE = "UPDATE";
export const SUCCESS_UPDATE = "SUCCESS_UPDATE";
export const FAILURE_UPDATE = "FAILURE_UPDATE";
export const FAILURE_POST_GUESTDOC_MAIL = "FAILURE_POST_GUESTDOC_MAIL";
export const SUCCESS_POST_GUESTDOC_MAIL = "SUCCESS_POST_GUESTDOC_MAIL";
export const POST_GUESTDOC_MAIL = "POST_GUESTDOC_MAIL";

export const SET_TC_LANG_CONTENT = "SET_TC_LANG_CONTENT";
