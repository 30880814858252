import api from "@api/onespeedGateway";
import * as types from "../../types";
import { checkMark } from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import { required, macRules } from "@utils/rules";
import Vue from "vue";
import moment from "moment";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("device"),
        value: "device",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.whitelist.headers.mac"
        ),
        value: "mac",
      },
      {
        text: Vue.i18n.translate("enabled"),
        value: "enabled",
        overrideValue: ({ enabled }) => checkMark(enabled),
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.whitelist.headers.expireAt"
        ),
        value: "expireAt",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.whitelist.headers.ticketId"
        ),
        value: "ticketId",
      },
      {
        text: Vue.i18n.translate("action"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state, unused, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "device",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.whitelist.items.device"
          ),
        },
        {
          type: "textfield",
          value: "mac",
          rules: [...required, ...macRules],
        },
        {
          type: "checkbox",
          value: "enabled",
        },
        {
          type: "datetime",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.whitelist.headers.expireAt"
          ),
          value: "expireAt",
        },
        {
          type: "autocomplete",
          value: "ticketId",
          itemValue: "id",
          itemText: (item) => {
            return item.i18n[0].title;
          },
          items: rootState.onespeedGatewayTicket.items,
          required: false,
          rules: [],
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.whitelist.headers.ticketId"
          ),
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexWhitelist({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexWhitelist({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveOrUpdateWhitelist({ commit }, { item, propertyId }) {
    if (item.id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateWhitelist({
            id: item.id,
            propertyId,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveWhitelist({
            item,
            propertyId,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deleteWhitelist({ commit }, { id, propertyId }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteWhitelist({
          id,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  async bulkDeleteWhitelist({ commit }, { propertyId, items }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.bulkDeleteWhitelist({
          items,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
    state.items.map((item) => {
      item.expireAt = item.expireAt
        ? moment(item.expireAt, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss")
        : "";
      return item;
    });
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state, value) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state, value) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state, value) {
    state.statusDelete = "success";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
