//import '@babel/polyfill'
import Vue from "vue";
import Vuetify from "./plugins/vuetify";
import "./plugins/vuetify-material-dashboard-free";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/i18n";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import VuePageTransition from "vue-page-transition";
Vue.use(VuePageTransition);
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);
import DatetimePicker from "vuetify-datetime-picker";
Vue.use(DatetimePicker);

Vue.config.productionTip = false;

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";

Vue.use(TiptapVuetifyPlugin, {
  vuetify: Vuetify,
  iconsGroup: "mdi",
});

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.config.productionTip = false;

import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: "UA-131772189-1",
  router,
  autoTracking: {
    exception: true,
  },
  debug: {
    sendHitTask: process.env.NODE_ENV === "production",
  },
});

try {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
} catch (e) {
  // ignore
}

export const vueInstance = new Vue({
  vuetify: Vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
