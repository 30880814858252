import { SettingsResource, SettingsSchema } from "./resources";

/*
  get: {method: 'GET'},
  save: {method: 'POST'},
  query: {method: 'GET'},
  update: {method: 'PUT'},
  remove: {method: 'DELETE'},
  delete: {method: 'DELETE'}
*/

export default {
  getSettings({ propertyId }) {
    return SettingsResource.get({ propertyId });
  },
  getSchema({ propertyId }) {
    return SettingsSchema.get({ propertyId });
  },
  updateSettings({ items, propertyId }) {
    return SettingsResource.update({ propertyId }, { ...items });
  },
};
