import api from "@api";
import * as types from "../types";
import Vue from "vue";
import { connectStatusOverride } from "@/utils/datatable-overrides";

const state = {
  items: [],
  status: void 0,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.property.id"),
        value: "propertyID",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.heartbeat.headers.projectDescription"
        ),
        value: "bridgeServiceProjectDescription",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.heartbeat.headers.bridgeServiceLastStatusTime"
        ),
        value: "bridgeServiceStatusTime",
      },
      {
        text: "Suite8",
        value: "suite8",
        overrideValue: ({ suite8 }) => connectStatusOverride(suite8),
      },
      {
        text: "Opera",
        value: "opera",
        overrideValue: ({ opera }) => connectStatusOverride(opera),
      },
      {
        text: "Simphony",
        value: "simphony",
        overrideValue: ({ simphony }) => connectStatusOverride(simphony),
      },
      {
        text: "Visionline",
        value: "visionline",
        overrideValue: ({ visionline }) => connectStatusOverride(visionline),
      },
      {
        text: "Salto",
        value: "salto",
        overrideValue: ({ salto }) => connectStatusOverride(salto),
      },
      {
        text: "Keystep",
        value: "keystep",
        overrideValue: ({ keystep }) => connectStatusOverride(keystep),
      },
      {
        text: "Messerschmitt",
        value: "messerschmitt",
        overrideValue: ({ messerschmitt }) =>
          connectStatusOverride(messerschmitt),
      },
      {
        text: "Radius",
        value: "radius",
        overrideValue: ({ radius }) => connectStatusOverride(radius),
      },
      {
        text: "FIAS",
        value: "fias",
        overrideValue: ({ fias }) => connectStatusOverride(fias),
      },
      {
        text: Vue.i18n.translate(
          "store.modules.heartbeat.headers.roomControlLastStatusTime"
        ),
        value: "roomControlStatusTime",
      },
      {
        text: Vue.i18n.translate("store.modules.heartbeat.headers.room"),
        value: "roomControlEnabled",
        overrideValue: ({ roomControlEnabled }) =>
          connectStatusOverride(roomControlEnabled),
      },
    ];
  },
};

const actions = {
  async indexHeartbeat({ commit }) {
    commit(types.FETCH_HEARTBEAT);
    try {
      commit(types.SUCCESS_FETCH_HEARTBEAT, await api.indexHeartbeat());
    } catch (e) {
      commit(types.FAILURE_FETCH_HEARTBEAT);
    }
  },
  async updateBridgeService({ commit }) {
    commit(types.REQUEST_REFRESH);
    try {
      commit(types.SUCCESS_REQUEST_REFRESH, await api.updateBridgeService());
    } catch (e) {
      commit(types.FAILURE_REQUEST_REFRESH);
    }
  },
};

const mutations = {
  [types.FETCH_HEARTBEAT](state) {
    state.status = "fetching";
  },
  [types.FAILURE_FETCH_HEARTBEAT](state) {
    state.status = "failure";
  },
  [types.SUCCESS_FETCH_HEARTBEAT](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_REFRESH](state) {
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST_REFRESH](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST_REFRESH](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
