import Vue from "vue";
import { API_ROOT_V3 } from "../../config";

export const LocationResource = Vue.resource(
  API_ROOT_V3 + "weather/location{/id}"
);
export const LocationIndexResource = Vue.resource(
  API_ROOT_V3 + "weather/location/index"
);
export const LocationSearchResource = Vue.resource(
  API_ROOT_V3 + "weather/location/search"
);
export const LocationFetchResource = Vue.resource(
  API_ROOT_V3 + "weather/location/fetch{/id}"
);
export const SettingsResource = Vue.resource(API_ROOT_V3 + "weather/settings");
export const SettingsSchemaResource = Vue.resource(
  API_ROOT_V3 + "weather/settings/schema"
);
