import Vue from "vue";
import { API_ROOT_V3 } from "../config";
import store from "@/store";
import router from "@/router";

Vue.http.options.crossOrigin = true;
Vue.http.options.credentials = true;
Vue.http.headers.delete = {};

Vue.http.interceptors.push(function (request) {
  request.headers = request.headers || {};
  request.headers.set(
    "X-AUTH-TOKEN",
    localStorage.getItem("atv_cms_x_auth_token")
  );

  const { accessToken } = store.state.auth;

  // this is a bit hacky, but the only way to know if we want to use the refreshtoken
  if (!request.headers.has("Authorization") && accessToken) {
    request.headers.set("Authorization", `Bearer ${accessToken}`);
  }
});

Vue.http.interceptors.push(function (request) {
  return function (response) {
    return new Promise(async (resolve, reject) => {
      if (response.status === 401) {
        if (
          !request.url.includes("auth/token") &&
          !request.url.includes("auth/verify-two-factor-auth-code")
        ) {
          await store.dispatch("auth/token", {
            nextpage: router.currentRoute.fullPath,
          });

          let resourceMethod = "get";
          switch (request.method) {
            case "POST":
              resourceMethod = "save";
              break;
            case "DELETE":
              resourceMethod = "delete";
              break;
            case "PUT":
              resourceMethod = "update";
              break;
          }

          await Vue.resource(request.url)
            [resourceMethod](request.params, request.body)
            .then(
              (response) => {
                resolve(response);
              },
              (response) => {
                resolve(response);
              }
            );
        } else {
          resolve(response);
        }
      } else if (response.status === 403) {
        reject(response);
        store.commit("error/ERROR_403");
      } else {
        resolve(response);
      }
    });
  };
});

export const LanguageIndexResource = Vue.resource(
  API_ROOT_V3 + "language/index"
);
export const UserResource = Vue.resource(API_ROOT_V3 + "user{/id}");
export const UserSessionResource = Vue.resource(API_ROOT_V3 + "user");
export const UserIndexResource = Vue.resource(API_ROOT_V3 + "user/index");
export const PropertyIndexResource = Vue.resource(
  API_ROOT_V3 + "property/index"
);
export const PropertyResource = Vue.resource(API_ROOT_V3 + "property{/id}");
export const PropertyGroupIndexResource = Vue.resource(
  API_ROOT_V3 + "property-group/index"
);
export const PropertyGroupResource = Vue.resource(
  API_ROOT_V3 + "property-group{/_id}"
);
export const ActivePropertyGroupResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/property-group/active/index"
);
export const PropertiesOnestreamLicenseResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/license"
);
export const PropertiesONEspeedGatewayLicenseResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onespeed/license"
);
export const PropertiesONEappLicenseResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/license"
);
export const PropertiesONEkeyLicenseResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onekey/license"
);

export const MediapoolFileIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/mediapool{/parentId}/file/index"
);
export const MediapoolResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/mediapool{/id}"
);
export const userForgotPasswordResource = Vue.resource(
  API_ROOT_V3 + "auth/login/forgot-password"
);
export const userForgotPasswordSetNewPasswordResource = Vue.resource(
  API_ROOT_V3 + "auth/login/forgot-password/set-new-password"
);

export const EmailTemplatesIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/email-template/index"
);

export const authTokenResource = Vue.resource(API_ROOT_V3 + "auth/token");
export const authInfoResource = Vue.resource(API_ROOT_V3 + "auth/info");
export const authLogoutResource = Vue.resource(API_ROOT_V3 + "auth/logout");
export const authVerifyTwoFactorAuthCodeResource = Vue.resource(
  API_ROOT_V3 + "auth/verify-two-factor-auth-code"
);
export const authResendTwoFactorAuthCodeResource = Vue.resource(
  API_ROOT_V3 + "auth/resend-two-factor-auth-code"
);

export const RoleIndexResource = Vue.resource(API_ROOT_V3 + "role/index");
export const RoleResource = Vue.resource(API_ROOT_V3 + "role{/_id}");
export const RolePermissionsIndexResource = Vue.resource(
  API_ROOT_V3 + "role/permissions/index"
);

export const HeartbeatIndexResource = Vue.resource(
  API_ROOT_V3 + "property/heartbeat/index"
);
export const HeartbeatUpdateResource = Vue.resource(
  API_ROOT_V3 + "property/heartbeat"
);
