import api from "@api/oneapp";
import * as types from "../../types";
import { imageOverride } from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import Vue from "vue";
import router from "@/router";
import { UpdateDeleteUpsellItemResource } from "@api/oneapp/resources";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

const state = {
  items: [],
  languages: [],
  status: undefined,
  statusSave: undefined,
  statusDelete: undefined,
  statusUpdate: undefined,
  error: undefined,
  conditions: [
    {
      title: "equals",
      value: "==",
    },
    {
      title: "not_equals",
      value: "!=",
    },
    {
      title: "less_than",
      value: "<",
    },
    {
      title: "greater_than",
      value: ">",
    },
    {
      title: "less_equal",
      value: "<=",
    },
    {
      title: "greater_equal",
      value: ">=",
    },
  ],
};

const getters = {
  getConditionByValue: (state) => (value) => {
    return state.conditions.find((el) => el.value === value);
  },
  getLanguages: (state) => {
    return state.languages;
  },
  upsellsHeader: (state) => () => {
    // All upsell items will have the same header
    if (state.items[0]?.header) {
      return state.items[0].header;
    }
    return "";
  },
};

function getUpsellCondIdx(state, cond_id) {
  let it;
  for (it = 0; it < state.items.length; it++) {
    if (state.items[it]._id === cond_id) {
      return { idx: it };
    }
  }

  return { idx: it };
}

function getUpsellItemIdx(state, cond_id, upsell_item_id) {
  let it;
  let it2;
  for (it = 0; it < state.items.length; it++) {
    if (state.items[it]._id === cond_id) {
      for (it2 = 0; it2 < state.items[it].items.length; it2++) {
        if (state.items[it].items[it2]._id == upsell_item_id) {
          return { idx: it, idx_2: it2 };
        }
      }
    }
  }

  it = -1;
  it2 = -1;

  return { idx: it, idx_2: it2 };
}

// actions
const actions = {
  async indexUpsellCond(
    { commit, rootState, dispatch },
    { propertyId = router.currentRoute.params.propertyId }
  ) {
    commit(types.REQUEST);

    try {
      await dispatch(
        "oneappLanguage/indexLanguage",
        { propertyId },
        { root: true }
      );

      let data = await api.indexUpsellCond({ propertyId });
      if (data.body.items) {
        // Add languages to old DB condition entries
        for (let condition of data.body.items) {
          for (let upsell of condition.items) {
            for (let lang of rootState.oneappLanguage.items) {
              if (!find(upsell.i18n, { iso2: lang.iso1 })) {
                upsell.i18n.push({
                  description: "",
                  iso2: lang.iso1,
                  title: "",
                });
              }
            }
          }
        }
      }

      commit(types.SUCCESS_REQUEST, data);
      commit("SET_LANGUAGES", rootState.oneappLanguage.items);
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },

  async createUpsellCond(
    { commit },
    { item, propertyId = router.currentRoute.params.propertyId }
  ) {
    commit(types.REQUEST);
    try {
      const res = await api.saveUpsellCond({
        item,
        propertyId,
      });

      if (!res.body) {
        commit(types.FAILURE_REQUEST);
        return false;
      }

      commit("SUCCESS_ADD_COND", res.body);
      return true;
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }

    return false;
  },

  async updateUpsellsHeader({ commit, dispatch, state }, header) {
    for (let item of state.items) {
      item.header = header;
      await dispatch("updateUpsellCond", {
        item,
        propertyId: router.currentRoute.params.propertyId,
      });
    }
  },

  async updateUpsellCond(
    { commit, state },
    { item, propertyId = router.currentRoute.params.propertyId }
  ) {
    commit(types.REQUEST_UPDATE);
    try {
      const { idx } = getUpsellCondIdx(state, item._id);
      if (idx === -1) return false;

      const res = await api.updateUpsellCond({
        _id: item._id,
        propertyId: propertyId,
        item: item,
      });

      if (!res.body) {
        commit(types.FAILURE_REQUEST);
        return false;
      }

      commit("SUCCESS_SAVE_COND", { value: res.body, idx: idx });
      return true;
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }

    return false;
  },

  async deleteUpsellCond(
    { state, commit },
    { _id, propertyId = router.currentRoute.params.propertyId }
  ) {
    commit(types.REQUEST_DELETE);
    try {
      const { idx } = getUpsellCondIdx(state, _id);
      if (idx === -1) return false;

      const res = await api.deleteUpsellCond({
        _id,
        propertyId,
      });

      if (!res.body) {
        commit(types.FAILURE_REQUEST_DELETE);
        return false;
      }

      commit("SUCCESS_DELETE_COND", idx);
      return true;
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }

    return false;
  },

  async createUpsellItem(
    { state, commit },
    { item, cond_id, propertyId = router.currentRoute.params.propertyId }
  ) {
    const { idx } = getUpsellCondIdx(state, cond_id);
    if (idx === -1) return false;

    commit(types.REQUEST_SAVE);
    try {
      const res = await api.saveUpsellItem({
        cond_id,
        propertyId,
        item,
      });

      if (!res.body) {
        commit(types.FAILURE_REQUEST_SAVE);
        return false;
      }

      commit("SUCCESS_SAVE_COND", { value: res.body, idx: idx });
      return true;
    } catch (e) {
      commit(types.FAILURE_REQUEST_SAVE);
    }

    return false;
  },

  async updateUpsellItem(
    { state, commit, dispatch },
    { cond_id, item, propertyId = router.currentRoute.params.propertyId }
  ) {
    const { idx, idx_2 } = getUpsellItemIdx(state, cond_id, item._id);
    if (idx === -1 || idx_2 === -1) return false;

    commit(types.REQUEST_UPDATE);
    try {
      const res = await api.updateUpsellItem({
        cond_id,
        upsell_id: item._id,
        propertyId,
        item,
      });

      if (!res.body) {
        commit(types.FAILURE_REQUEST_UPDATE);
        return false;
      }

      commit("SUCCESS_UPDATE_ITEM", { value: res.body, idx });
      return true;
    } catch (e) {
      commit(types.FAILURE_REQUEST_UPDATE);
    }

    return false;
  },

  async deleteUpsellItem(
    { commit, state },
    { cond_id, upsell_id, propertyId = router.currentRoute.params.propertyId }
  ) {
    const { idx, idx_2 } = getUpsellItemIdx(state, cond_id, upsell_id);
    if (idx === -1 || idx_2 === -1) return false;

    commit(types.REQUEST_DELETE);
    try {
      const res = await api.deleteUpsellItem({
        cond_id,
        upsell_id,
        propertyId,
      });

      if (!res.body) {
        commit(types.FAILURE_REQUEST_DELETE);
        return false;
      }

      commit("SUCCESS_DELETE_ITEM", { idx, idx_2 });
      return true;
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }

    return false;
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    if (value && !isEmpty(value.body.items)) {
      state.items = value.body.items.map((i) => {
        if (!i.items) {
          i.items = [];
        }
        return i;
      });
    }
  },
  ["SET_LANGUAGES"](state, items) {
    state.languages = items;
  },
  ["SUCCESS_ADD_COND"](state, value) {
    state.status = "success";
    state.items.push(value);
  },
  ["SUCCESS_SAVE_COND"](state, { value, idx }) {
    state.statusSave = "success";
    state.items[idx] = Object.assign({}, value);
  },
  ["SUCCESS_DELETE_COND"](state, idx) {
    state.status = "success";
    state.items.splice(idx, 1);
  },
  ["SUCCESS_ADD_ITEM"](state, { value, idx }) {
    state.status = "success";
    state.items[idx].items.push(value);
  },
  ["SUCCESS_SAVE_ITEM"](state, { value, idx }) {
    state.status = "success";
    state.items[idx] = Object.assign({}, value);
  },
  ["SUCCESS_UPDATE_ITEM"](state, { value, idx }) {
    state.statusUpdate = "success";
    state.items[idx] = Object.assign({}, value);
  },
  ["SUCCESS_DELETE_ITEM"](state, { idx, idx_2 }) {
    state.statusDelete = "success";
    state.items[idx].items.splice(idx_2, 1);
  },
  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state, value) {
    state.statusSave = "failure";
    state.error = value;
  },
  [types.SUCCESS_REQUEST_SAVE](state) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusDelete = "success";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
