export const emailRules = [
  (v) => {
    return !!v || "E-Mail is required";
  },
  (v) =>
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
    "E-Mail must be valid",
];

export const ipRules = [
  (v) => {
    return !!v || "IP is required";
  },
  (v) =>
    /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(v) ||
    "IP must be valid",
];

export const ipOptionalRules = [
  (v) =>
    /^$|^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(v) ||
    "IP must be valid",
];

function checkName(str) {
  const rules = [
    {
      //e.g. Chromecast 1
      regex: /^Chromecast\s(\d+)(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}.chromecast`,
    },
    {
      // e.g. Room 122
      regex: /^Room\s(\d+)(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}.iptv`,
    },
    {
      // e.g. 122 Living Room
      // e.g. 122 Living Room TV
      regex: /^(\d+)\sLiving Room(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}lr.iptv`,
    },
    {
      // e.g. 122 Living  Room
      // e.g. 122 Living    Room TV
      regex: /^(\d+)\sLiving\s+Room(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}lr.iptv`,
    },
    {
      // e.g. 122 Living Room 2
      // e.g. 122 Living Room 2 TV
      regex: /^(\d+)\sLiving Room\s(\d+)(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}lr${matches[2]}.iptv`,
    },
    {
      // e.g. 122 Bedroom
      // e.g. 122 Bedroom TV
      regex: /^(\d+)\sBedroom(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}br.iptv`,
    },
    {
      // e.g. 122 Bedroom 2
      // e.g. 122 Bedroom 2 TV
      regex: /^(\d+)\sBedroom\s(\d+)(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}br${matches[2]}.iptv`,
    },
    {
      // e.g. 122 Bathroom
      // e.g. 122 Bathroom TV
      regex: /^(\d+)\sBathroom(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}bath.iptv`,
    },
    {
      // e.g. 122 Bathroom 2
      // e.g. 122 Bathroom 2 TV
      regex: /^(\d+)\sBathroom\s(\d+)(?:\sTV)?$/,
      uid: (matches) => `${matches[1]}bath${matches[2]}.iptv`,
    },
  ];

  for (const rule of rules) {
    const matches = str.match(rule.regex);
    if (matches) {
      return true;
    }
  }

  return false;
}

export const nameRules = [(v) => !!checkName(v) || "Name has invalid format!"];

export const required = [(v) => !!v || "required"];

export const macRules = [
  (v) => {
    return !!v || "MAC Address is required";
  },
  (v) =>
    /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i.test(v) ||
    "MAC Address must be valid",
];

export const priceRules = [
  (v) =>
    /^\d+([.,]\d+)?$/.test(v) || "price must be in valid format, eg. 999,99",
];

export const fourDigitRules = [
  (v) => (/\d{4}/.test(v) || !v ? true : "Must be 4 digits, eg.: 1234"),
];
