import api from "@api/onespeedGateway";
import * as types from "../../types";
import { API_ROOT_V3 } from "@/config";
import { checkMark } from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import { priceRules } from "@/utils/rules";
import each from "lodash/each";
import Vue from "vue";
import router from "@/router";

const state = {
  items: [],
  bandwidthUnits: ["Mbit/s"],
  volumeUnits: ["MB", "GB", "TB"],
  timeUnits: ["s", "m", "h", "d"],
  statusBulkTickets: "",
  statusCreateBulk: "",
  bulkTickets: [],
  bulkTicketRooms: [],
  selectedTemplate: void 0,
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  statusDeleteBulk: "",
  statusCSV: "",
  statusPDF: "",
  statusSendBulkTicketEmail: "",
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("title"),
        value: "title",
        overrideValue: ({ i18n = [{ title: "" }] }) => {
          return [{ value: i18n[0].title }];
        },
      },
      {
        text: Vue.i18n.translate("enabled"),
        value: "enabled",
        overrideValue: ({ enabled }) => checkMark(enabled),
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.globalReauthEnabled"
        ),
        value: "globalReauthEnabled",
        overrideValue: ({ globalReauthEnabled }) =>
          checkMark(globalReauthEnabled),
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.expireInHours"
        ),
        value: "expireInHours",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.maxDevices"
        ),
        value: "maxDevices",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.idleTimeoutInSeconds"
        ),
        value: "idleTimeoutInSeconds",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.uiInputTimeLimit"
        ),
        value: "uiInputTimeLimit",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.uiInputBandwidthUp"
        ),
        value: "uiInputBandwidthUp",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.uiInputBandwidthDown"
        ),
        value: "uiInputBandwidthDown",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.ciscoGuestRole"
        ),
        value: "ciscoGuestRole",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.uiInputDataLimit"
        ),
        value: "uiInputDataLimit",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.uiInputPrice"
        ),
        value: "priceAmount",
        overrideValue: ({ priceCurrency, priceAmount }) => {
          return [{ value: `${priceCurrency} ${priceAmount}` }];
        },
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.headers.pmsID"
        ),
        value: "pmsId",
      },
      {
        text: Vue.i18n.translate("action"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  bulkHeaders: () => {
    return [
      {
        text: Vue.i18n.translate("name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.bulkHeaders.timeLimitInSeconds"
        ),
        value: "timeLimitInSeconds",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.bulkHeaders.validTo"
        ),
        value: "validTo",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.ticket.bulkHeaders.createdAt"
        ),
        value: "createdAt",
      },
      {
        text: Vue.i18n.translate("action"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state, unused, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "title",
          multiLanguage: true,
        },
        {
          type: "checkbox",
          value: "enabled",
        },
        {
          type: "checkbox",
          value: "globalReauthEnabled",
        },
        {
          type: "textfield",
          value: "expireInHours",
          required: true,
          rules: [],
          valueType: "number",
          permission: "onespeed.bulkTickets.ticket.override.expireInHours",
        },
        {
          type: "textfield",
          value: "idleTimeoutInSeconds",
          required: false,
          rules: [],
          valueType: "number",
        },
        {
          type: "textfield",
          value: "maxDevices",
          required: false,
          rules: [],
          valueType: "number",
        },
        {
          type: "textfield",
          value: "priceCurrency",
          flex: 6,
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.ticket.items.priceAmount"
          ),
          value: "priceAmount",
          rules: [...priceRules],
          flex: 6,
        },
        {
          type: "textfield",
          value: "pmsId",
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "uiInputBandwidthDown",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.ticket.items.uiInputBandwidthDown"
          ),
          select: {
            value: "uiUnitBandwidthDown",
            items: state.bandwidthUnits,
          },
          required: false,
          valueType: "number",
          rules: [],
        },
        {
          type: "textfield",
          value: "uiInputBandwidthUp",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.ticket.items.uiInputBandwidthUp"
          ),
          select: {
            value: "uiUnitBandwidthUp",
            items: state.bandwidthUnits,
          },
          required: false,
          valueType: "number",
          rules: [],
        },
        {
          type: "textfield",
          value: "ciscoGuestRole",
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "uiInputDataLimit",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.ticket.items.uiInputDataLimit"
          ),
          select: {
            value: "uiUnitDataLimit",
            items: state.volumeUnits,
          },
          required: false,
          valueType: "number",
          rules: [],
        },
        {
          type: "textfield",
          value: "uiInputTimeLimit",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.ticket.items.uiInputTimeLimit"
          ),
          select: {
            value: "uiUnitTimeLimit",
            items: state.timeUnits,
          },
          required: false,
          valueType: "number",
          rules: [],
        },
      ],
      languages: rootState.onespeedGatewayLanguage.items,
    });
    return schema;
  },
  bulkItemSchema: (state, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "name",
        },
        {
          type: "textfield",
          value: "timeLimitInSeconds",
        },
        {
          type: "datetime",
          value: "validTo",
        },
        {
          type: "datetime",
          value: "createdAt",
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexTicket({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexTicket({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async indexBulkTicket({ commit }, { propertyId }) {
    commit(types.REQUEST_BULK_TICKETS);
    try {
      commit(
        types.SUCCESS_REQUEST_BULK_TICKETS,
        await api.indexBulkTicket({ propertyId })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_BULK_TICKETS);
    }
  },
  async getRoomsBulkTicket(
    { commit },
    { propertyId = router.currentRoute.params.propertyId }
  ) {
    commit(types.REQUEST_ROOMS_BULK_TICKETS);
    try {
      commit(
        types.SUCCESS_REQUEST_ROOMS_BULK_TICKETS,
        await api.getRoomsBulkTicket({ propertyId })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_ROOMS_BULK_TICKETS);
    }
  },
  async createBulkTicket({ commit, dispatch }, { propertyId, item }) {
    commit(types.REQUEST_BULK_CREATE);
    try {
      const result = await api.createBulkTicket({ propertyId, item });
      commit(types.SUCCESS_BULK_CREATE);
      dispatch("indexBulkTicket", { propertyId });
      return result.data._id;
    } catch (e) {
      commit(types.FAILURE_BULK_CREATE, e);
    }
  },
  async deleteBulkTicket({ commit, dispatch }, { propertyId, ticketId }) {
    commit(types.REQUEST_BULK_DELETE);
    try {
      commit(
        types.SUCCESS_BULK_DELETE,
        await api.deleteBulkTicket({ ticketId, propertyId })
      );
      dispatch("indexBulkTicket", { propertyId });
    } catch (e) {
      commit(types.FAILURE_BULK_DELETE);
    }
  },
  async saveOrUpdateTicket({ commit }, { item, propertyId }) {
    if (item.id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateTicket({
            id: item.id,
            propertyId,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveTicket({
            item,
            propertyId,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deleteTicket({ commit }, { id, propertyId }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteTicket({
          id,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  async bulkDeleteTicket({ commit }, { propertyId, items }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.bulkDeleteTicket({
          items,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  async getBulkTicketCSV({ commit }, { ticketId, name, propertyId }) {
    commit(types.REQUEST_BULK_CSV);
    try {
      let value = await api.getBulkTicketCSV({ ticketId, propertyId });
      commit(types.SUCCESS_REQUEST_BULK_CSV, {
        body: value.body,
        id: ticketId,
        name,
      });
    } catch (e) {
      commit(types.FAILURE_REQUEST_BULK_CSV);
    }
  },
  async getBulkTicketPDF(
    { commit, rootState },
    { ticketId, propertyId, action }
  ) {
    commit(types.REQUEST_BULKTICKET_PDF);
    try {
      const { accessToken } = rootState.auth;
      const response = await fetch(
        `${API_ROOT_V3}${propertyId}/onespeed/bulkticket/${ticketId}/pdf`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      const blob = await response.blob();
      const contentDisposition = response.headers.get("content-disposition");
      let filename = "wifi-tickets.pdf"; // Default filename

      if (contentDisposition) {
        const matches = contentDisposition.match(/filename=([^;]+)/);
        if (matches && matches[1]) {
          filename = matches[1].trim();
        }
      }
      const url = window.URL.createObjectURL(blob);

      if (action == "download") {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else if (action == "print") {
        const newWindow = window.open(url, "_blank");
        newWindow.onload = function () {
          newWindow.print();
        };
      }

      // For Firefox it is necessary to delay revoking the ObjectURL
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 100);
      commit(types.SUCCESS_REQUEST_BULKTICKET_PDF);
    } catch (e) {
      commit(types.FAILURE_REQUEST_BULKTICKET_PDF);
    }
  },
  async sendBulkTicketEmail({ commit }, { ticketId, propertyId, email }) {
    commit(types.REQUEST_SEND_BULKTICKET_EMAIL);
    try {
      await api.postBulkTicketEmail({ ticketId, propertyId, email });
      commit(types.SUCCESS_REQUEST_SEND_BULKTICKET_EMAIL);
    } catch (_) {
      commit(types.FAILURE_REQUEST_SEND_BULKTICKET_EMAIL);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusDelete = "success";
  },
  [types.SET_SELECTED_TEMPLATE](state, value) {
    const { selectedTemplate = {} } = state;
    const { validFrom = null, validTo = null } = selectedTemplate;
    state.selectedTemplate = {
      ...value,
      validFrom,
      validTo,
    };
  },
  [types.REQUEST_BULK_TICKETS](state) {
    state.statusBulkTickets = "fetching";
  },
  [types.SUCCESS_REQUEST_BULK_TICKETS](state, value) {
    state.statusBulkTickets = "success";
    let tickets = [];
    each(value.body, (ticket) => {
      tickets.push({ ...ticket, id: ticket._id });
    });
    state.bulkTickets = tickets;
  },
  [types.FAILURE_REQUEST_BULK_TICKETS](state) {
    state.statusBulkTickets = "failure";
  },
  [types.REQUEST_ROOMS_BULK_TICKETS](state) {
    state.statusRoomsBulkTickets = "fetching";
  },
  [types.SUCCESS_REQUEST_ROOMS_BULK_TICKETS](state, { body = [] }) {
    state.statusRoomsBulkTickets = "success";
    state.bulkTicketRooms = body;
  },
  [types.FAILURE_REQUEST_ROOMS_BULK_TICKETS](state) {
    state.statusRoomsBulkTickets = "failure";
  },
  [types.REQUEST_BULK_CREATE](state) {
    state.statusCreateBulk = "fetching";
  },
  [types.SUCCESS_BULK_CREATE](state) {
    state.statusCreateBulk = "success";
  },
  [types.FAILURE_BULK_CREATE](state) {
    state.statusCreateBulk = "failure";
  },
  [types.REQUEST_BULK_DELETE](state) {
    state.statusDeleteBulk = "fetching";
  },
  [types.SUCCESS_BULK_DELETE](state) {
    state.statusDeleteBulk = "success";
  },
  [types.FAILURE_BULK_DELETE](state) {
    state.statusDeleteBulk = "failure";
  },
  [types.REQUEST_BULK_CSV](state) {
    state.statusCSV = "fetching";
  },
  [types.SUCCESS_REQUEST_BULK_CSV](state, value) {
    state.statusCSV = "success";
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(value.body);
    hiddenElement.target = "_blank";
    hiddenElement.download = `${value.name}.csv`;
    hiddenElement.click();
  },
  [types.FAILURE_REQUEST_BULK_CSV](state) {
    state.statusCSV = "failure";
  },
  [types.REQUEST_BULKTICKET_PDF](state) {
    state.statusPDF = "fetching";
  },
  [types.SUCCESS_REQUEST_BULKTICKET_PDF](state) {
    state.statusPDF = "success";
  },
  [types.FAILURE_REQUEST_BULKTICKET_PDF](state) {
    state.statusPDF = "failure";
  },

  [types.REQUEST_SEND_BULKTICKET_EMAIL](state) {
    state.statusSendBulkTicketEmail = "fetching";
  },
  [types.SUCCESS_REQUEST_SEND_BULKTICKET_EMAIL](state) {
    state.statusSendBulkTicketEmail = "success";
  },
  [types.FAILURE_REQUEST_SEND_BULKTICKET_EMAIL](state) {
    state.statusSendBulkTicketEmail = "failure";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
