import Vue from "vue";
import { API_ROOT_V3 } from "../../config";

export const PropertiesOnestreamLicenseResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/license"
);

export const DeviceResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/device{/id}"
);
export const DeviceIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/device/index"
);
export const DevicePropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/device"
);

export const SettingsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/settings"
);
export const SettingsSchema = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/settings/schema"
);
export const StatsIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/stats"
);
export const ServiceMappingsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/mappings{/id}"
);
export const ServiceUsageResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/onestream/stats/usage/services"
);
