const Toolbar = () => import("@/components/core/Toolbar");
const Dashboard = () => import("@/views/Dashboard");
const title = "ONEapp Webcheckin";
const key = "oneapp_webcheckin";

export default [
  {
    path: "/property/:propertyId/oneapp/webcheckin/dashboard",
    name: "oneappWebcheckinDashboard",
    components: {
      default: Dashboard,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
];
