import * as types from "../../types";
import { getSchema } from "@/utils/schema";
import {
  checkMark,
  profileOverride,
  checkVerified,
} from "@/utils/datatable-overrides";
import api from "@api/onespeedGateway";
import Vue from "vue";
import moment from "moment";
import filter from "lodash/filter";
import truncate from "lodash/truncate";
import { macRules } from "@utils/rules";

const state = {
  items: [],
  profile: {},
  profileStatus: "",
  status: "",
  statusUpdate: "",
  statusUpdateError: "",
  statusSave: "",
  sendDisconnectDevicesStatus: "",
  profileExportStatus: "",
  totalCount: void 0,
  page: 0,
  itemsPerPage: 50,
  selectedProfile: {},
};

const getters = {
  itemSchema: (state, unused, rootState) => {
    return getSchema({
      items: [
        {
          type: "textfield",
          label: Vue.i18n.translate("store.modules.user.headers.firstname"),
          value: "firstName",
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          label: Vue.i18n.translate("store.modules.user.headers.lastname"),
          value: "lastName",
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          label: Vue.i18n.translate("store.modules.user.headers.email"),
          value: "email",
          required: false,
          rules: [],
        },
        {
          type: "autocomplete",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.monitoring.headers.room"
          ),
          value: "room",
          required: false,
          items: rootState.generalCheckinRoomManager.items
            .filter((el) => el.data.room != undefined)
            .map((el) => el.data.room)
            .sort((a, b) => a - b),
          rules: [],
        },
        {
          type: "textfield",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.monitoring.headers.voucherCode"
          ),
          value: "accessCode",
          required: false,
          rules: [],
        },
        {
          type: "switch",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.monitoring.headers.anonymous"
          ),
          value: "anonymous",
        },
        {
          type: "textfield",
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.monitoring.headers.card"
          ),
          value: "card",
          required: false,
          rules: [],
        },
        {
          type: "macList",
          value: "devices",
          required: false,
          label: Vue.i18n.translate(
            "store.modules.onespeedGateway.monitoring.headers.deviceMacsList"
          ),
          rules: [...macRules],
          readonly: false,
        },
        {
          type: "autocomplete",
          value: "ticketId",
          itemValue: "id",
          itemText: (item) => {
            return item.i18n[0].title;
          },
          items: rootState.onespeedGatewayTicket.items,
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "maxBandwidthDownInMbit",
          suffix: "Mbit/s",
          required: false,
          rules: [],
          valueType: "number",
          permission: "onespeed.profiles.ticket.override.bandwidth",
        },
        {
          type: "textfield",
          value: "maxBandwidthUpInMbit",
          suffix: "Mbit/s",
          required: false,
          rules: [],
          valueType: "number",
          permission: "onespeed.profiles.ticket.override.bandwidth",
        },
        {
          type: "textfield",
          value: "timeLimitInSeconds",
          required: false,
          rules: [],
          readonly: true,
          permission: "onespeed.profiles.ticket.override.timeLimitInSeconds",
        },
        {
          type: "textfield",
          value: "dataLimitInMb",
          required: false,
          rules: [],
          readonly: true,
          permission: "onespeed.profiles.ticket.override.dataLimitInMb",
        },
        {
          type: "textfield",
          value: "expireInHours",
          required: false,
          rules: [],
          readonly: false,
          permission: "onespeed.profiles.ticket.override.expireInHours",
        },
        {
          type: "textfield",
          value: "maxDevices",
          required: false,
          rules: [],
          readonly: false,
          permission: "onespeed.profiles.ticket.override.maxDevices",
        },
        {
          type: "textfield",
          value: "ciscoGuestRole",
          required: false,
          rules: [],
          readonly: true,
          permission: "onespeed.profiles.ticket.override.ciscoGuestRole",
        },
      ],
      languages: rootState.onespeedGatewayLanguage.items,
    });
  },
  headers: () => {
    return [
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.profileName"
        ),
        value: "profile",
        overrideValue: ({ profile, icon }) => profileOverride(profile, icon),
        sortable: true,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.bandwidthUpDown"
        ),
        value: "ticket.maxBandwidthDownUp",
        sortable: false,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.dataUsage"
        ),
        value: "usedData.dataTxRX",
        sortable: false,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.totalSessionTime"
        ),
        value: "usedData.sessionTime",
        sortable: false,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.deviceMacs"
        ),
        value: "mac",
        sortable: false,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.deviceIPs"
        ),
        value: "ip",
        sortable: false,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.propertyName"
        ),
        value: "propertyName",
        sortable: false,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.createdAt"
        ),
        value: "createdAt",
        sortable: true,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.updatedAt"
        ),
        value: "updatedAt",
        sortable: true,
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.valid"
        ),
        value: "valid",
        sortable: true,
        overrideValue: ({ valid }) => checkMark(valid),
        hidden: false,
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onespeedGateway.monitoring.headers.verified"
        ),
        value: "emailValidated",
        sortable: false,
        overrideValue: ({ emailValidated }) => checkVerified(emailValidated),
        hidden: false,
      },
      {
        text: Vue.i18n.translate("action"),
        value: "action",
        sortable: false,
        align: "right",
        hidden: false,
      },
      {
        value: "data-table-expand",
        sortable: false,
        hidden: false,
      },
    ];
  },
};

const actions = {
  async initOnespeedGatewayLoginTypes({ dispatch }, { propertyId }) {
    dispatch(
      "onespeedGatewayLoginTypes/indexLoginTypes",
      { propertyId },
      { root: true }
    );
  },
  async indexProfiles(
    { commit, state },
    {
      propertyId,
      search,
      showEmails,
      showRooms,
      showAccessCodes,
      showAnonymous,
      showCards,
      connected,
      limit = state.itemsPerPage,
      page = state.page,
      sortBy = sortDesc !== undefined ? sortBy : undefined,
      sortDesc,
    }
  ) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexProfiles({
          propertyId,
          search,
          showEmails,
          showRooms,
          showAccessCodes,
          showAnonymous,
          showCards,
          connected,
          limit,
          page,
          sortBy,
          sortDesc,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async exportProfiles(
    { commit, state },
    {
      propertyId,
      search,
      showEmails,
      showRooms,
      showAccessCodes,
      showAnonymous,
      showCards,
      connected,
      sortBy = sortDesc !== undefined ? sortBy : undefined,
      sortDesc,
    }
  ) {
    commit(types.REQUEST_PROFILE_EXPORT);
    try {
      commit(
        types.SUCCESS_REQUEST_PROFILE_EXPORT,
        await api.exportProfiles({
          propertyId,
          search,
          showEmails,
          showRooms,
          showAccessCodes,
          showAnonymous,
          showCards,
          connected,
          sortBy,
          sortDesc,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_PROFILE_EXPORT);
    }
  },

  async deleteProfile({ commit }, { id, propertyID }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteProfile({
          id,
          propertyID,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },

  async getProfile({ commit }, { id, propertyId }) {
    commit(types.REQUEST_PROFILE);
    try {
      commit(
        types.SUCCESS_REQUEST_PROFILE,
        await api.getProfile({ id, propertyId })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_PROFILE);
    }
  },
  async setProfile({ commit }, profile) {
    commit(types.SUCCESS_REQUEST_PROFILE, { body: profile });
  },
  async saveOrUpdateProfile({ commit }, { propertyId, item }) {
    const ticket = {
      ciscoGuestRole: item.ciscoGuestRole,
      dataLimitInMb: item.dataLimitInMb,
      expireInHours: item.expireInHours,
      maxBandwidthDownInMbit: item.maxBandwidthDownInMbit,
      maxBandwidthUpInMbit: item.maxBandwidthUpInMbit,
      maxDevices: item.maxDevices,
      timeLimitInSeconds: item.timeLimitInSeconds,
      ticketId: item.ticketId,
    };

    item.ticket = void 0;
    item.ticket = ticket;

    if (item._id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateProfile({
            id: item._id,
            propertyId,
            item,
          })
        );
      } catch (e) {
        const { body = {} } = e;
        const { error = "" } = body;
        commit(types.FAILURE_REQUEST_UPDATE, error);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveProfile({
            item,
            propertyId,
          })
        );
      } catch (e) {
        const { body = {} } = e;
        const { error = "" } = body;
        commit(types.FAILURE_REQUEST_SAVE, error);
      }
    }
  },
  async sendDisconnectDevices({ commit }, { propertyId, macs, disconnect }) {
    commit(types.REQUEST_DISCONNECT_DEVICES);
    try {
      commit(
        types.SUCCESS_REQUEST_DISCONNECT_DEVICES,
        await api.sendDisconnectDevices({ propertyId, macs, disconnect })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DISCONNECT_DEVICES);
    }
  },
};

const mutations = {
  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state) {
    state.statusSave = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusSave = "success";
  },

  RESET_STATUS_UPDATE(state) {
    state.statusUpdate = "";
    state.statusUpdateError = "";
  },
  [types.REQUEST_UPDATE](state) {
    state.statusUpdateError = "";
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state, error = "") {
    state.statusUpdate = "failure";
    state.statusUpdateError = error;
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_PROFILE](state) {
    state.profile = {};
    state.profileStatus = "fetching";
  },
  [types.SUCCESS_REQUEST_PROFILE](state, { body }) {
    state.profile = body;
    state.profile = {
      ...state.profile.ticket,
      ...state.profile,
    };
    state.profile.devices = state.profile.devices.map((dev) => {
      return {
        ...dev,
        confirm: false,
      };
    });
    state.profileStatus = "success";
  },
  [types.FAILURE_REQUEST_PROFILE](state) {
    state.profileStatus = "failure";
  },

  [types.REQUEST](state) {
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.totalCount = value.body.totalCount;
    state.items = value.body.items;

    state.items = state.items.map((item) => {
      let socialLogin =
        filter(Object.keys(item.identity), (o) => {
          return o === "facebook" || o === "linkedin";
        })[0] || void 0;
      if (socialLogin) {
        socialLogin = Vue.i18n.translate(
          `components.onespeedGatewaySettings.LogonTypes.${socialLogin}`
        );
      }

      let icon;
      if (item.identity.email) {
        icon = "mdi-email";
      } else if (item.identity.room) {
        icon = "mdi-bed";
      } else if (item.identity.accessCode) {
        icon = "mdi-ticket";
      } else if (item.identity.anonymous) {
        icon = "mdi-incognito-circle";
      } else if (item.identity.card) {
        icon = "mdi-account";
      }

      const mac = truncate(item.identity.macs.toString(), { length: 39 });
      const ipAddressesString = item.devices
        .filter((device) => device.ip && device.ip.trim() !== "")
        .map((device) => device.ip)
        .join(",");
      const ip = truncate(ipAddressesString, { length: 39 });

      return {
        ...item,
        icon,
        profile:
          item.identity.email ||
          item.identity.accessCode ||
          item.identity.room ||
          socialLogin ||
          (item.identity.anonymous ? mac : "") ||
          item.identity.card ||
          "",
        socialLogin,
        createdAt: moment(item.createdAt, moment.ISO_8601).format(
          "DD.MM.YYYY HH:mm:ss"
        ),
        updatedAt: moment(item.updatedAt, moment.ISO_8601).format(
          "DD.MM.YYYY HH:mm:ss"
        ),
        valid: item.ticket.valid,
        sentEmailValidation: item.sentEmailValidation
          ? moment(item.sentEmailValidation, moment.ISO_8601).format(
              "DD.MM.YYYY HH:mm:ss"
            )
          : undefined,
        mac,
        ip,
        usedDataPercent:
          item.usedData.dataInPercent === "-"
            ? -1
            : parseInt(item.usedData.dataInPercent),
        usedTimePercent:
          item.usedData.sessionTimeInPercent === "-"
            ? -1
            : parseInt(item.usedData.sessionTimeInPercent),
        expireTimeInPercent:
          item.usedData.expireTimeInPercent === "-"
            ? -1
            : parseInt(item.usedData.expireTimeInPercent),
      };
    });
  },

  [types.REQUEST_DISCONNECT_DEVICES](state) {
    state.sendDisconnectDevicesStatus = "fetching";
  },
  [types.FAILURE_REQUEST_DISCONNECT_DEVICES](state) {
    state.sendDisconnectDevicesStatus = "failure";
  },
  [types.SUCCESS_REQUEST_DISCONNECT_DEVICES](state) {
    state.sendDisconnectDevicesStatus = "success";
  },

  [types.REQUEST_PROFILE_EXPORT](state) {
    state.profileExportStatus = "fetching";
  },
  [types.FAILURE_REQUEST_PROFILE_EXPORT](state) {
    state.profileExportStatus = "failure";
  },
  [types.SUCCESS_REQUEST_PROFILE_EXPORT](state, value) {
    state.profileExportStatus = "success";

    const filename = (value.headers.get("content-disposition") || "").split(
      "filename="
    )[1];
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(value.body);
    hiddenElement.target = "_blank";
    hiddenElement.download = `${filename}.csv`;
    hiddenElement.click();
  },
  SET_SELECTED_PROFILE(state, value) {
    state.selectedProfile = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
