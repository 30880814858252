import {
  LocationIndexResource,
  LocationResource,
  LocationSearchResource,
  LocationFetchResource,
  SettingsResource,
  SettingsSchemaResource,
} from "./resources";

export default {
  indexLocation() {
    return LocationIndexResource.get();
  },
  updateLocation({ id, item }) {
    return LocationResource.update({ id }, item);
  },
  saveLocation({ item, id }) {
    return LocationResource.save({ id }, item);
  },
  deleteLocation({ id }) {
    return LocationResource.delete({ id });
  },
  bulkDeleteLocation({ items }) {
    return LocationResource.delete({}, items);
  },
  searchLocation({ query, countryCode }) {
    return LocationSearchResource.get({ query, cc: countryCode });
  },
  fetchLocation({ id }) {
    return LocationFetchResource.get({ id }, {});
  },

  getSettings() {
    return SettingsResource.get();
  },
  getSchema() {
    return SettingsSchemaResource.get();
  },
  updateSettings({ items }) {
    return SettingsResource.update({}, { ...items });
  },
};
