import rootApi from "@api";
import api from "@api/onespeedGateway";
import * as types from "../../types";
import { make } from "vuex-pathify";

const state = {
  data: {},
  status: void 0,
  statusUpdate: void 0,
  emailTemplates: [],
  statusEmailTemplates: void 0,
};

function postProcess(loginType) {
  if (loginType.dailypointLogin) {
    if (Object.hasOwn(loginType.dailypointLogin, "programId")) {
      loginType.dailypointLogin.programId = parseInt(
        loginType.dailypointLogin.programId
      );
    }

    if (Object.hasOwn(loginType.dailypointLogin, "newsletterId")) {
      loginType.dailypointLogin.newsletterId = parseInt(
        loginType.dailypointLogin.newsletterId
      );
    }

    if (Object.hasOwn(loginType.dailypointLogin, "propertyId")) {
      loginType.dailypointLogin.propertyId = parseInt(
        loginType.dailypointLogin.propertyId
      );
    }
  }
}

// actions
const actions = {
  async indexConfirmationEmailTemplates({ commit }, { propertyId }) {
    commit(types.REQUEST_CONFIRMATION_EMAIL_TEMPLATES);
    try {
      commit(
        types.SUCCESS_REQUEST_CONFIRMATION_EMAIL_TEMPLATES,
        await rootApi.indexEmailTemplates({
          propertyId,
          emailType: "confirmation_email",
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_CONFIRMATION_EMAIL_TEMPLATES);
    }
  },
  async indexLoginTypes({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexLoginTypes({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveLoginTypes({ commit }, { data = {}, propertyId }) {
    commit(types.REQUEST_SAVE);
    try {
      postProcess(data);
      commit(
        types.SUCCESS_REQUEST_SAVE,
        await api.saveLoginTypes({
          data,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_SAVE);
    }
  },
};

const mutations = {
  ...make.mutations(state),
  [types.REQUEST](state) {
    state.data = {};
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.data = value.body;
  },

  [types.REQUEST_SAVE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_CONFIRMATION_EMAIL_TEMPLATES](state) {
    state.statusEmailTemplates = "fetching";
  },
  [types.FAILURE_REQUEST_CONFIRMATION_EMAIL_TEMPLATES](state) {
    state.statusEmailTemplates = "failure";
  },
  [types.SUCCESS_REQUEST_CONFIRMATION_EMAIL_TEMPLATES](state, value) {
    state.statusEmailTemplates = "success";
    state.emailTemplates = value.body.items;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
