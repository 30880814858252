import api from "@api/onestream";
import * as types from "../../types";
import {
  checkMark,
  onlineOfflineOverride,
  yesNoOverride,
} from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import {
  fourDigitRules,
  ipOptionalRules,
  ipRules,
  macRules,
  nameRules,
  required,
} from "@utils/rules";
import router from "@/router";
import Vue from "vue";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate("store.modules.onestream.device.headers.room"),
        value: "room",
      },
      {
        text: Vue.i18n.translate("store.modules.onestream.device.headers.uid"),
        value: "uid",
      },
      {
        text: Vue.i18n.translate("store.modules.onestream.device.headers.mac"),
        value: "mac",
      },
      {
        text: Vue.i18n.translate("store.modules.onestream.device.headers.ip"),
        value: "ip",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onestream.device.headers.encoderIp"
        ),
        value: "encoderIp",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onestream.device.headers.stream"
        ),
        value: "stream",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onestream.device.headers.deviceType"
        ),
        value: "deviceType",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onestream.device.headers.standalone"
        ),
        value: "standalone",
        overrideValue: ({ standalone }) => yesNoOverride(standalone),
      },
      {
        text: Vue.i18n.translate("store.modules.onestream.device.headers.pin"),
        value: "pin",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.onestream.device.headers.online"
        ),
        value: "online",
        overrideValue: ({ online }) => onlineOfflineOverride(online),
      },
      {
        text: Vue.i18n.translate("active"),
        value: "active",
        overrideValue: ({ active }) => checkMark(active),
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  types: () => {
    return [
      {
        value: "appletv",
        text: Vue.i18n.translate(
          "store.modules.onestream.device.types.appleTv"
        ),
      },
      {
        value: "chromecast",
        text: Vue.i18n.translate(
          "store.modules.onestream.device.types.chromecast"
        ),
      },
    ];
  },
  itemSchema: (state, getters) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "name",
          required: true,
        },
        {
          type: "autocomplete",
          value: "deviceType",
          items: getters.types,
        },
        {
          type: "textfield",
          value: "room",
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "uid",
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "mac",
          rules: [...required, ...macRules],
          readonly: true,
        },
        {
          type: "textfield",
          value: "ip",
          rules: [...required, ...ipRules],
          readonly: true,
        },
        {
          type: "textfield",
          value: "encoderIp",
          required: false,
          rules: [...ipOptionalRules],
        },
        {
          type: "textfield",
          value: "stream",
        },
        {
          type: "textfield",
          value: "pin",
          required: false,
          rules: [...fourDigitRules],
        },
        {
          type: "checkbox",
          value: "standalone",
        },
        {
          type: "checkbox",
          value: "active",
          readonly: false,
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexDevice({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexDevice({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveOrUpdateDevice(
    { commit },
    { item, propertyId = router.currentRoute.params.propertyId }
  ) {
    if (item.id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateDevice({
            id: item.id,
            propertyId,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveDevice({
            item,
            propertyId,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deleteDevice({ commit }, { id, propertyId }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteDevice({
          id,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusDelete = "success";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
