import api from "@api/onespeedGateway";
import * as types from "../../types";
import {
  yesNoOverride,
  imageOverride,
  colorOverride,
} from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import Vue from "vue";
import theme from "../oneapp/theme";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  error: void 0,
  snackbar: false,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate("title"),
        value: "title",
      },
      {
        text: Vue.i18n.translate("theme.primaryColor"),
        value: "primary",
        overrideValue: (theme) => {
          if (theme.dark) {
            return colorOverride(theme.primaryDark);
          } else {
            return colorOverride(theme.primary);
          }
        },
      },
      {
        text: Vue.i18n.translate("theme.logoBackgroundColor"),
        value: "logoBackgroundColor",
        overrideValue: (theme) => {
          if (theme.dark) {
            return colorOverride(theme.logoBackgroundColorDark);
          } else {
            return colorOverride(theme.logoBackgroundColor);
          }
        },
      },
      {
        text: Vue.i18n.translate("theme.logoImage"),
        value: "logoImage",
        overrideValue: (theme) => {
          if (theme.dark) {
            return imageOverride(theme.logoImageDark);
          } else {
            return imageOverride(theme.logoImage);
          }
        },
      },
      {
        text: Vue.i18n.translate("theme.appBackgroundImage"),
        value: "appBackgroundImage",
        overrideValue: (theme) => {
          if (theme.dark) {
            return imageOverride(theme.appBackgroundImageDark);
          } else {
            return imageOverride(theme.appBackgroundImage);
          }
        },
      },
      {
        text: Vue.i18n.translate("theme.dark"),
        value: "dark",
        overrideValue: ({ dark }) => yesNoOverride(dark),
      },
      {
        text: Vue.i18n.translate("action"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "name",
        },
        {
          type: "textfield",
          value: "title",
        },
        {
          type: "switch",
          value: "dark",
          label: Vue.i18n.translate("theme.setDarkDefault"),
        },
        {
          type: "tabGroup",
          value: "themeContent",
          tabs: [
            {
              id: 1,
              label: Vue.i18n.translate("theme.light"),
              items: [
                {
                  type: "color",
                  value: "primary",
                  label: Vue.i18n.translate("theme.primaryColor"),
                },
                {
                  type: "color",
                  value: "logoBackgroundColor",
                  label: Vue.i18n.translate("theme.logoBackgroundColor"),
                },
                {
                  type: "media",
                  value: "logoImage",
                  label: Vue.i18n.translate("theme.logoImage"),
                },
                {
                  type: "media",
                  value: "appBackgroundImage",
                  label: Vue.i18n.translate("theme.appBackgroundImage"),
                },
              ],
            },
            {
              id: 0,
              label: Vue.i18n.translate("theme.dark"),
              items: [
                {
                  type: "color",
                  value: "primaryDark",
                  label: Vue.i18n.translate("theme.primaryColor"),
                },
                {
                  type: "color",
                  value: "logoBackgroundColorDark",
                  label: Vue.i18n.translate("theme.logoBackgroundColor"),
                },
                {
                  type: "media",
                  value: "logoImageDark",
                  label: Vue.i18n.translate("theme.logoImage"),
                },
                {
                  type: "media",
                  value: "appBackgroundImageDark",
                  label: Vue.i18n.translate("theme.appBackgroundImage"),
                },
              ],
            },
          ],
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexTheme({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexTheme({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveOrUpdateTheme({ commit }, { item, propertyId }) {
    if (item.id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateTheme({
            id: item.id,
            propertyId,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveTheme({
            item,
            propertyId,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE, e);
        commit(types.SET_SNACKBAR, true);
      }
    }
  },
  async deleteTheme({ commit }, { id, propertyId }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteTheme({
          id,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  async bulkDeleteTheme({ commit }, { propertyId, items }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.bulkDeleteTheme({
          items,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state, value) {
    state.statusSave = "success";
    state.error = value;
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusDelete = "success";
  },
  [types.SET_SNACKBAR](state, value) {
    state.snackbar = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
