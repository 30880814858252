import api from "@api";
import * as types from "../types";
import { checkMark } from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import Vue from "vue";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  statusRolePermissions: void 0,
  rolePermissions: [],
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.role.headers.name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate("store.modules.role.headers.permissions"),
        value: "permissions.length",
      },
      {
        text: Vue.i18n.translate("enabled"),
        value: "enabled",
        overrideValue: ({ enabled }) => checkMark(enabled),
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state, unused, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "name",
        },
        {
          type: "autocomplete",
          multiple: true,
          chips: true,
          deletableChips: true,
          value: "permissions",
          itemText: (item) => {
            return item.value;
          },
          items: rootState.role.rolePermissions,
        },
        {
          type: "checkbox",
          value: "enabled",
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexRole({ commit }) {
    commit(types.REQUEST);
    try {
      commit(types.SUCCESS_REQUEST, await api.indexRole());
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveOrUpdateRole({ commit }, { item }) {
    if (item._id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateRole({
            _id: item._id,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveRole({
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deleteRole({ commit }, { _id }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteRole({
          _id,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  async getRolePermissions({ commit }) {
    commit("REQUEST_ROLE_PERMISSIONS");
    try {
      commit(
        "SUCCESS_REQUEST_ROLE_PERMISSIONS",
        await api.indexRolePermissions()
      );
    } catch (e) {
      commit("FAILURE_REQUEST_ROLE_PERMISSIONS");
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state, value) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state, value) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state, value) {
    state.statusDelete = "success";
  },

  REQUEST_ROLE_PERMISSIONS(state) {
    state.statusRolePermissions = "fetching";
  },
  FAILURE_REQUEST_ROLE_PERMISSIONS(state) {
    state.statusRolePermissions = "failure";
  },
  SUCCESS_REQUEST_ROLE_PERMISSIONS(state, { body = {} }) {
    const { permissions = [] } = body;
    state.statusRolePermissions = "success";
    state.rolePermissions = permissions;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
