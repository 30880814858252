import api from "@api/oneapp";
import * as types from "../../types";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
  statusDelete: void 0,
};

// actions
const actions = {
  async indexLanguage({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexLanguage({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveLanguage({ commit }, { items = [], propertyId }) {
    commit(types.REQUEST_SAVE);
    try {
      commit(
        types.SUCCESS_REQUEST_SAVE,
        await api.saveLanguage({
          items,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_SAVE);
    }
  },
  async deleteLanguage({ commit }, { id }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteLanguage({
          id,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state) {
    state.statusDelete = "success";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
