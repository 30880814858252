const Toolbar = () => import("@/components/core/Toolbar");
const Dashboard = () => import("@/views/Dashboard");
const Settings = () => import("@/views/onesquare/Settings");
const title = "ONEsquare";
const key = "onesquare";

export default [
  {
    path: "/property/:propertyId/onesquare/dashboard",
    name: "onesquareDashboard",
    components: {
      default: Dashboard,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onesquare/settings",
    name: "onesquareSettings",
    components: {
      default: Settings,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
];
