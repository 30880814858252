import api from "@api/oneapp";
import * as types from "../../types";
import Vue from "vue";
import { AgeFromDate } from "age-calculator";
import { getSchema } from "@/utils/schema";
import router from "@/router";
import {
  postGuestDocMail,
  getTemplate,
  getRegistrationformTemplate,
} from "@/api/oneapp/guestDocMail";
import Handlebars from "handlebars";
const state = {
  items: [],
  status: "",
  checkinStatus: "",
  updateStatus: "",
  error: undefined,
  mailStatus: "",
  siteInfo: { businessTravelerOvernightStay: {} },
  reservation: undefined,
  filteredHeaders: [],
};

const getters = {
  rules: () => {
    return {
      required: [(value) => !!value || Vue.i18n.translate("rules.required")],
      minimumAge: [
        (value) =>
          new AgeFromDate(new Date(value))._age >= 18 ||
          Vue.i18n.translate("guest.minimumAge"),
      ],
    };
  },
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("oneapp.reservations.reservationNumber"),
        value: "reservationNumber",
      },
      {
        text: Vue.i18n.translate("oneapp.reservations.firstname"),
        value: "firstname",
      },
      {
        text: Vue.i18n.translate("oneapp.reservations.lastname"),
        value: "lastname",
      },
      {
        text: Vue.i18n.translate("oneapp.reservations.end"),
        value: "end",
      },
      {
        text: Vue.i18n.translate("oneapp.reservations.resStatus"),
        value: "resStatus",
      },
      {
        text: Vue.i18n.translate("oneapp.reservations.roomNumber"),
        value: "roomNumber",
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
        disabledFilter: true,
      },
    ];
  },
  filteredHeaders: (state) => {
    if (state.filteredHeaders && state.filteredHeaders.length > 0) {
      return state.headers.filter((item) => {
        return (
          state.filteredHeaders
            .map((item) => item.value)
            .includes(item.value) || state.headers
        );
      });
    } else {
      return state.headers;
    }
  },
  itemSchema: (state, unused, rootState) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "reservationNumber",
          label: Vue.i18n.translate("oneapp.reservations.reservationNumber"),
        },
        {
          type: "textfield",
          value: "firstname",
          label: Vue.i18n.translate("oneapp.reservations.firstname"),
        },
        {
          type: "textfield",
          value: "lastname",
          label: Vue.i18n.translate("oneapp.reservations.lastname"),
        },
        {
          type: "date",
          value: "end",
          label: Vue.i18n.translate("oneapp.reservations.end"),
          multiLanguage: true,
        },
        {
          type: "textfield",
          value: "resStatus",
          label: Vue.i18n.translate("oneapp.reservations.resStatus"),
        },
        {
          type: "textfield",
          value: "roomNumber",
          label: Vue.i18n.translate("oneapp.reservations.roomNumber"),
        },
      ],
      languages: rootState.oneappLanguage.items,
    });
    return schema;
  },
};

const actions = {
  async getReservations(
    { commit, rootState },
    {
      propertyId = router.currentRoute.params.propertyId,
      pms = rootState.oneappSettings.items.webcheckin.pmsAccessInfo
        .configuredPms,
    }
  ) {
    commit("FETCH_RESERVATIONS");
    try {
      commit(
        "SET_FILTERED_HEADERS",
        Vue.$cookies.get("oneappReservations-filtered-headers")
      );
    } catch (error) {
      // ignore
    }

    try {
      const today = new Date();
      const date = [
        today.getFullYear(),
        ("0" + (today.getMonth() + 1)).slice(-2),
        ("0" + today.getDate()).slice(-2),
      ].join("-");
      commit(
        "SUCCESS_FETCH_RESERVATIONS",
        await api.getReservations({ propertyId, pms, date })
      );
    } catch (error) {
      commit("FAILURE_FETCH_RESERVATIONS", error);
    }
  },
  async checkin(
    { commit },
    {
      propertyId = router.currentRoute.params.propertyId,
      checkinDate,
      lastName,
      confirmationNumber,
    }
  ) {
    commit("CHECKIN");
    try {
      commit(
        "SUCCESS_CHECKIN",
        await api.checkin({
          propertyId,
          checkinDate,
          lastname: lastName,
          confirmationNumber,
        })
      );
    } catch (e) {
      commit("FAILURE_CHECKIN");
    }
  },
  async profileUpdate(
    { commit, dispatch, state },
    { propertyId = router.currentRoute.params.propertyId, guestProfile }
  ) {
    commit("UPDATE");
    try {
      const gp = {
        ...guestProfile,
        start: state.reservation.start,
        end: state.reservation.end,
        reservationNumber: state.reservation.reservationNumber,
        lastname: state.reservation.lastname,
        roomNumber: state.reservation.roomNumber,
        propertyId,
        reservationRequest: {
          checkinDate: state.reservation.start,
          confirmationNumber: state.reservation.reservationNumber,
          lastname: state.reservation.lastname,
          propertyId,
        },
      };
      commit(
        "SUCCESS_UPDATE",
        await api.profileUpdate({
          propertyId,
          guestProfile: gp,
        })
      );

      dispatch("postGuestDocMail", { guestProfile: gp });
    } catch (error) {
      commit("FAILURE_UPDATE");
    }
  },
  getPropertyInfo(
    { commit },
    { property = router.currentRoute.params.propertyId }
  ) {
    let model = null;
    let schema = null;
    let certificateOfRegistration = null;

    try {
      schema = require(`@/content/guestRegistrationConfig/${property}/businessTravelerOvernightStay/schema.json`);
    } catch (ignore) {}

    if (!schema) {
      try {
        schema = require(`@/content/guestRegistrationConfig/default/businessTravelerOvernightStay/schema.json`);
      } catch (ignore) {
        schema = {};
      }
    }

    try {
      model = require(`@/content/guestRegistrationConfig/${property}/businessTravelerOvernightStay/model.json`);
    } catch (ignore) {}

    if (!model) {
      try {
        model = require(`@/content/guestRegistrationConfig/default/businessTravelerOvernightStay/model.json`);
      } catch (ignore) {
        model = {};
      }
    }

    try {
      certificateOfRegistration = require(`@/content/guestRegistrationConfig/${property}/certificateOfRegistration.json`);
    } catch (ignore) {}
    if (!certificateOfRegistration) {
      try {
        certificateOfRegistration = require(`@/content/guestRegistrationConfig/default/certificateOfRegistration.json`);
      } catch (ignore) {
        certificateOfRegistration = {};
      }
    }

    try {
      const siteInfo = {
        ...state.siteInfo,
        certificateOfRegistration,
      };
      commit("SET_INFO", siteInfo);
    } catch (ignore) {}

    const siteInfo = {
      ...state.siteInfo,
      businessTravelerOvernightStay: {
        model,
        schema,
      },
    };
    commit("SET_INFO", siteInfo);
  },
  async postGuestDocMail(
    { commit, state, rootState },
    { propertyId = router.currentRoute.params.propertyId, guestProfile = {} }
  ) {
    commit("POST_GUESTDOC_MAIL");
    try {
      let attachmentItems = [];
      //Heckfield-place always wants to have the additional Document shown
      if (
        rootState.oneappSettings.items.webcheckin.additionalDocument ===
          "always" ||
        (rootState.oneappSettings.items.webcheckin.additionalDocument ===
          "onlyBusiness" &&
          guestProfile.isCompanyLinked)
      ) {
        const { source } = await getTemplate(propertyId);
        let template = Handlebars.compile(source);
        let html = template({ ...data });

        attachmentItems.push({
          html,
          pdfFileName: "Overnight.pdf",
        });
      }

      let registrationSource = await getRegistrationformTemplate(propertyId);
      let registrationTemplate = Handlebars.compile(registrationSource);
      let regHtml = registrationTemplate({
        ...guestProfile,
        confirmationNumber: guestProfile.reservationNumber,
        room: guestProfile.roomNumber,
        checkinDate: guestProfile.start,
        checkoutDate: guestProfile.end,
      });

      attachmentItems.push({
        html: regHtml,
        pdfFileName: "Certificate of Registration.pdf",
      });
      commit(
        "SUCCESS_POST_GUESTDOC_MAIL",
        await postGuestDocMail({
          property: propertyId,

          attachmentItems,

          subject: `${guestProfile.firstName} ${guestProfile.lastname} | ${guestProfile.start}`,
          emailMsg: rootState.oneappSettings.items.webcheckin.regCardMailText,
        })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      commit("FAILURE_POST_GUESTDOC_MAIL");
    }
  },
  async getProfile(
    { commit },
    {
      propertyId = router.currentRoute.params.propertyId,
      checkinDate,
      lastName,
      confirmationNumber,
    }
  ) {
    commit("FETCH_RESERVATION");
    try {
      commit(
        "SUCCESS_FETCH_RESERVATION",
        await api.getProfile({
          propertyId,
          checkinDate,
          lastName,
          confirmationNumber,
        })
      );
    } catch (error) {
      commit("FAILURE_FETCH_RESERVATION", error);
    }
  },
};

const mutations = {
  [types.FETCH_RESERVATIONS](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.SUCCESS_FETCH_RESERVATIONS](state, value) {
    state.status = "success";
    state.items = value.body.reservations.reservationList;
  },
  [types.FAILURE_FETCH_RESERVATIONS](state, value) {
    state.status = "failure";
    state.error = value;
  },
  [types.FETCH_RESERVATION](state) {
    state.reservation = undefined;
    state.status = "fetching";
  },
  [types.SUCCESS_FETCH_RESERVATION](state, value) {
    state.status = "success";
    state.reservation = value;
  },
  [types.FAILURE_FETCH_RESERVATION](state, value) {
    state.status = "failure";
    state.error = value;
  },
  [types.SET_INFO](state, value) {
    state.siteInfo = value;
  },
  [types.CHECKIN](state) {
    state.checkinStatus = "fetching";
  },
  [types.SUCCESS_CHECKIN](state) {
    state.checkinStatus = "success";
  },
  [types.FAILURE_CHECKIN](state) {
    state.checkinStatus = "failure";
  },
  [types.UPDATE](state) {
    state.updateStatus = "fetching";
  },
  [types.SUCCESS_UPDATE](state) {
    state.updateStatus = "success";
  },
  [types.FAILURE_UPDATE](state) {
    state.updateStatus = "failure";
  },
  [types.POST_GUESTDOC_MAIL](state) {
    state.mailStatus = "fetching";
  },
  [types.SUCCESS_POST_GUESTDOC_MAIL](state) {
    state.mailStatus = "success";
  },
  [types.FAILURE_POST_GUESTDOC_MAIL](state) {
    state.mailStatus = "failure";
  },
  SET_FILTERED_HEADERS(state, value = []) {
    if (typeof value === "string") {
      value = JSON.parse(value);
    }

    state.filteredHeaders = value;
    Vue.$cookies.set(
      "oneappReservations-filtered-headers",
      JSON.stringify(value),
      "1y"
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
