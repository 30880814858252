const Toolbar = () => import("@/components/core/Toolbar");
const InfoText = () => import("@/views/onekey/InfoText");
const Language = () => import("@/views/onekey/Language");
const title = "";
const key = "onekey";

export default [
  {
    path: "/property/:propertyId/onekey/info-text",
    name: "onekeyInfoText",
    components: {
      default: InfoText,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onekey/language",
    name: "onekeyLanguage",
    components: {
      default: Language,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
];
