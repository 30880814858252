const Toolbar = () => import("@/components/core/Toolbar");
const Dashboard = () => import("@/views/onespeed/Dashboard");
const Whitelist = () => import("@/views/onespeed/Whitelist");
const Blocklist = () => import("@/views/onespeed/Blocklist");
const Language = () => import("@/views/onespeed/Language");
const TermsAndConditions = () => import("@/views/onespeed/TermsAndConditions");
const Themes = () => import("@/views/onespeed/Themes");
const Tickets = () => import("@/views/onespeed/Tickets");
const BulkTickets = () => import("@/views/onespeed/BulkTickets");
const Settings = () => import("@/views/onespeed/Settings");
const Monitoring = () => import("@/views/onespeed/Monitoring");
const title = "ONEspeed Gateway";
const key = "onespeed_gateway";

export default [
  {
    path: "/property/:propertyId/onespeed-gateway/dashboard",
    name: "onespeedGatewayDashboard",
    components: {
      default: Dashboard,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/whitelist",
    name: "onespeedGatewayWhitelist",
    components: {
      default: Whitelist,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/blocklist",
    name: "onespeedGatewayBlocklist",
    components: {
      default: Blocklist,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/language",
    name: "onespeedGatewayLanguage",
    components: {
      default: Language,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/terms-and-conditions",
    name: "onespeedGatewayTermsAndConditions",
    components: {
      default: TermsAndConditions,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/theme",
    name: "onespeedGatewayTheme",
    components: {
      default: Themes,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/tickets",
    name: "onespeedGatewayTicket",
    components: {
      default: Tickets,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/bulk-tickets",
    name: "onespeedGatewayBulkTickets",
    components: {
      default: BulkTickets,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/settings",
    name: "onespeedGatewaySettings",
    components: {
      default: Settings,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/onespeed-gateway/monitoring",
    name: "onespeedGatewayMonitoring",
    components: {
      default: Monitoring,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
];
