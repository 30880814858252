import Vue from "vue";
import { API_ROOT_V3 } from "../../config";

export const GuestResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/guest{/_id}"
);
export const GuestIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/guest/index"
);
export const GuestPropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/guest"
);

export const GuestPropertyPingResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/guest/ping"
);
export const GuestPropertySyncResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/guest/sync"
);
