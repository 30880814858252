import { BTONSResource, TemplateResource } from "./resources";
import router from "@/router";
export function postGuestDocMail(data) {
  return new Promise((resolve, reject) => {
    BTONSResource.save(
      { propertyId: data.property, email: router.currentRoute.query.eMail },
      data
    ).then(
      (response) => {
        resolve(response);
      },
      (response) => {
        reject(response);
      }
    );
  });
}

export function getTemplate(propertyId) {
  let template = { template: "" };

  try {
    template = require(`@/content/guestRegistrationConfig/${propertyId}/businessTravelerOvernightStay/template.js`);
  } catch (ignore) {}

  if (!template.template) {
    try {
      let template = require(`@/content/guestRegistrationConfig/default/businessTravelerOvernightStay/template.js`);
    } catch (ignore) {}
  }

  return {
    source: template.template,
  };
}

export function getRegistrationformTemplate(propertyId) {
  let template = require(`@/content/guestRegistrationConfig/${propertyId}/certificateTemplate.js`);
  return template.template;
}
