import api from "@api/checkinRoomManager";
import * as types from "../../types";
import { postNoPostOverride } from "@/utils/datatable-overrides";
import { getSchema } from "@/utils/schema";
import { required } from "@utils/rules";
import Vue from "vue";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  statusPing: void 0,
  statusSync: void 0,
  pingResult: void 0,
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.checkinRoomManager.room"),
        value: "data.room",
      },
      {
        text: Vue.i18n.translate(
          "store.modules.checkinRoomManager.reservationNr"
        ),
        value: "data.reservationNr",
      },
      {
        text: Vue.i18n.translate("store.modules.checkinRoomManager.checkin"),
        value: "data.checkin",
      },
      {
        text: Vue.i18n.translate("store.modules.checkinRoomManager.checkout"),
        value: "data.checkout",
      },
      {
        text: Vue.i18n.translate("store.modules.checkinRoomManager.title"),
        value: "data.title",
      },
      {
        text: Vue.i18n.translate("store.modules.checkinRoomManager.firstName"),
        value: "data.firstName",
      },
      {
        text: Vue.i18n.translate("store.modules.checkinRoomManager.lastName"),
        value: "data.lastName",
      },
      {
        text: Vue.i18n.translate("store.modules.checkinRoomManager.language"),
        value: "data.language",
      },
      {
        text: Vue.i18n.translate("store.modules.checkinRoomManager.post"),
        value: "data.noPost",
        overrideValue: ({ data }) => postNoPostOverride(data.noPost),
      },
      {
        text: Vue.i18n.translate("action"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "data.room",
          label: Vue.i18n.translate("store.modules.checkinRoomManager.room"),
          rules: [...required],
        },
        {
          type: "textfield",
          value: "data.reservationNr",
          label: Vue.i18n.translate(
            "store.modules.checkinRoomManager.reservationNr"
          ),
          rules: [...required],
        },
        {
          type: "date",
          value: "data.checkin",
          label: Vue.i18n.translate("store.modules.checkinRoomManager.checkin"),
          required: false,
          rules: [],
        },
        {
          type: "date",
          value: "data.checkout",
          label: Vue.i18n.translate(
            "store.modules.checkinRoomManager.checkout"
          ),
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "data.title",
          label: Vue.i18n.translate("store.modules.checkinRoomManager.title"),
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "data.firstName",
          label: Vue.i18n.translate(
            "store.modules.checkinRoomManager.firstName"
          ),
          rules: [...required],
        },
        {
          type: "textfield",
          value: "data.lastName",
          label: Vue.i18n.translate(
            "store.modules.checkinRoomManager.lastName"
          ),
          rules: [...required],
        },
        {
          type: "textfield",
          value: "data.language",
          label: Vue.i18n.translate(
            "store.modules.checkinRoomManager.language"
          ),
          rules: [...required],
        },
        {
          type: "checkbox",
          value: "data.shareFlag",
          label: Vue.i18n.translate(
            "store.modules.checkinRoomManager.shareFlag"
          ),
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "data.groupNr",
          label: Vue.i18n.translate("store.modules.checkinRoomManager.groupNr"),
          required: false,
          rules: [],
        },
        {
          type: "textfield",
          value: "data.vipStatus",
          label: Vue.i18n.translate(
            "store.modules.checkinRoomManager.vipStatus"
          ),
          required: false,
          rules: [],
        },
        {
          type: "checkbox",
          value: "data.noPost",
          label: Vue.i18n.translate("store.modules.checkinRoomManager.noPost"),
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexGuest({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexGuest({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async saveOrUpdateGuest({ commit }, { item, propertyId }) {
    if (item._id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateGuest({
            _id: item._id,
            propertyId,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveGuest({
            item,
            propertyId,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deleteGuest({ commit }, { _id, propertyId }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteGuest({
          _id,
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },

  async pingInterface({ commit }, { propertyId }) {
    commit(types.REQUEST_PING);
    try {
      commit(
        types.SUCCESS_REQUEST_PING,
        await api.pingInterface({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_PING);
    }
  },
  async syncPMS({ commit }, { propertyId }) {
    commit(types.REQUEST_SYNC);
    try {
      await api.syncPMS({
        propertyId,
      });
      commit(types.SUCCESS_REQUEST_SYNC);
    } catch (e) {
      commit(types.FAILURE_REQUEST_SYNC);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state, value) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state, value) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state, value) {
    state.statusDelete = "success";
  },

  [types.REQUEST_PING](state) {
    state.statusPing = "fetching";
    state.statusSync = "";
    state.pingResult = "";
  },
  [types.FAILURE_REQUEST_PING](state) {
    state.statusPing = "failure";
    state.pingResult = "";
  },
  [types.SUCCESS_REQUEST_PING](state, value) {
    state.statusPing = "success";
    state.pingResult = value.body;
  },

  [types.REQUEST_SYNC](state) {
    state.statusSync = "fetching";
    state.statusPing = "";
  },
  [types.FAILURE_REQUEST_SYNC](state) {
    state.statusSync = "failure";
  },
  [types.SUCCESS_REQUEST_SYNC](state) {
    state.statusSync = "success";
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
