import {
  UserResource,
  UserSessionResource,
  UserIndexResource,
  userForgotPasswordResource,
  RoleResource,
  RoleIndexResource,
  RolePermissionsIndexResource,
  PropertyIndexResource,
  PropertyResource,
  PropertiesOnestreamLicenseResource,
  PropertiesONEspeedGatewayLicenseResource,
  PropertiesONEappLicenseResource,
  PropertiesONEkeyLicenseResource,
  LanguageIndexResource,
  MediapoolResource,
  MediapoolFileIndexResource,
  PropertyGroupResource,
  PropertyGroupIndexResource,
  userForgotPasswordSetNewPasswordResource,
  EmailTemplatesIndexResource,
  HeartbeatIndexResource,
  HeartbeatUpdateResource,
  ActivePropertyGroupResource,
} from "./resources";

/*
  get: {method: 'GET'},
  save: {method: 'POST'},
  query: {method: 'GET'},
  update: {method: 'PUT'},
  remove: {method: 'DELETE'},
  delete: {method: 'DELETE'}
*/

export default {
  getUser({ id }) {
    return UserResource.get({
      id,
    });
  },
  indexUser() {
    return UserIndexResource.get();
  },
  updateSessionUser({ item }) {
    return UserSessionResource.update({
      ...item,
    });
  },
  updateUser({ id, item }) {
    return UserResource.update(
      {
        id,
      },
      {
        ...item,
      }
    );
  },
  saveUser({ item }) {
    return UserResource.save(
      {},
      {
        ...item,
      }
    );
  },
  deleteUser({ _id }) {
    return UserResource.delete({ id: _id });
  },

  indexProperty() {
    return PropertyIndexResource.get();
  },
  updateProperty({ id, item }) {
    return PropertyResource.update(
      {
        id,
      },
      {
        ...item,
      }
    );
  },
  saveProperty({ item }) {
    return PropertyResource.save(
      {},
      {
        ...item,
      }
    );
  },
  deleteProperty({ id }) {
    return PropertyResource.delete({
      id,
    });
  },
  getPropertyById({ id }) {
    return PropertyResource.get({
      id,
    });
  },

  indexPropertyGroup() {
    return PropertyGroupIndexResource.get();
  },
  updatePropertyGroup({ _id, item }) {
    return PropertyGroupResource.update(
      {
        _id,
      },
      {
        ...item,
      }
    );
  },
  savePropertyGroup({ item }) {
    return PropertyGroupResource.save(
      {},
      {
        ...item,
      }
    );
  },
  deletePropertyGroup({ _id }) {
    return PropertyGroupResource.delete({
      _id,
    });
  },
  getPropertyGroupById({ _id }) {
    return PropertyGroupResource.get({
      _id,
    });
  },

  getActiveGroupById({ propertyId }) {
    return ActivePropertyGroupResource.get({
      propertyId,
    });
  },

  getOnestreamLicensesByPropertyId({ propertyId }) {
    return PropertiesOnestreamLicenseResource.get({
      propertyId,
    });
  },
  saveOrUpdateOnestreamLicense({ propertyId, item }) {
    return PropertiesOnestreamLicenseResource.update(
      {
        propertyId,
      },
      {
        ...item,
      }
    );
  },

  getONEspeedGatewayLicensesByPropertyId({ propertyId }) {
    return PropertiesONEspeedGatewayLicenseResource.get({
      propertyId,
    });
  },
  saveOrUpdateONEspeedGatewayLicense({ propertyId, item }) {
    return PropertiesONEspeedGatewayLicenseResource.update(
      {
        propertyId,
      },
      {
        ...item,
      }
    );
  },

  getONEappLicensesByPropertyId({ propertyId }) {
    return PropertiesONEappLicenseResource.get({ propertyId });
  },
  saveOrUpdateONEappLicense({ propertyId, item }) {
    return PropertiesONEappLicenseResource.update({ propertyId }, { ...item });
  },

  getONEkeyLicensesByPropertyId({ propertyId }) {
    return PropertiesONEkeyLicenseResource.get({
      propertyId,
    });
  },
  saveOrUpdateONEkeyLicense({ propertyId, item }) {
    return PropertiesONEkeyLicenseResource.update(
      {
        propertyId,
      },
      {
        ...item,
      }
    );
  },
  indexLanguage() {
    return LanguageIndexResource.get();
  },
  indexMediapoolFiles({ propertyId }) {
    return MediapoolFileIndexResource.get({ propertyId });
  },
  updateMediapool({ id, propertyId, item }) {
    return MediapoolResource.update({ id, propertyId }, { ...item });
  },
  deleteMediapool({ id, propertyId }) {
    return MediapoolResource.delete({ id, propertyId });
  },
  userForgotPassword({ email }) {
    return userForgotPasswordResource.save({}, { email });
  },
  userForgotPasswordSetNewPassword({ code, password }) {
    return userForgotPasswordSetNewPasswordResource.save(
      {},
      { code, password }
    );
  },
  indexRolePermissions() {
    return RolePermissionsIndexResource.get();
  },
  indexRole() {
    return RoleIndexResource.get();
  },
  updateRole({ _id, item }) {
    return RoleResource.update(
      {
        _id,
      },
      item
    );
  },
  saveRole({ item }) {
    return RoleResource.save({}, item);
  },
  deleteRole({ _id }) {
    return RoleResource.delete({
      _id,
    });
  },
  indexEmailTemplates({ propertyId, emailType }) {
    return EmailTemplatesIndexResource.get({ propertyId, type: emailType });
  },
  indexHeartbeat() {
    return HeartbeatIndexResource.get();
  },
  updateBridgeService() {
    return HeartbeatUpdateResource.save();
  },
};
