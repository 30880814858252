import api from "@api/onespeedGateway";
import * as types from "../../types";

const state = {
  items: [],
  status: void 0,
  statusUpdate: void 0,
};

// actions
const actions = {
  async indexTermsAndConditions({ commit }, { propertyId }) {
    commit(types.REQUEST);
    try {
      commit(
        types.SUCCESS_REQUEST,
        await api.indexTermsAndConditions({
          propertyId,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async updateTermsAndConditions({ commit }, { items, propertyId }) {
    commit(types.REQUEST_UPDATE);
    try {
      commit(
        types.SUCCESS_REQUEST_UPDATE,
        await api.updateTermsAndConditions({
          propertyId,
          items,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_UPDATE);
    }
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.items = [];
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state) {
    state.statusUpdate = "success";
  },
  [types.SET_TC_LANG_CONTENT](state, { content, index }) {
    state.items[index].content.text = content;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
