import api from "@api";
import * as types from "../types";
import remove from "lodash/remove";
import includes from "lodash/includes";
import filter from "lodash/filter";
import { getSchema } from "@/utils/schema";
import Vue from "vue";

const addPropertyToLocalstorageSelectedProperty = function (id) {
  let arr = JSON.parse(localStorage.getItem("selected-properties")) || [];
  arr = remove(arr, function (arrId) {
    return arrId != id;
  });
  if (arr.length > 3) {
    arr.length = 3;
  }
  arr = [id, ...arr];
  localStorage.setItem("selected-properties", JSON.stringify(arr));
};

const state = {
  items: [],
  status: void 0,
  itemById: {},
  statusById: void 0,
  statusUpdate: void 0,
  statusSave: void 0,
  statusDelete: void 0,
  selectedProperty: {},
  propertyId: void 0,
  recentlySelectedProperties: [],
};

const getters = {
  headers: () => {
    return [
      {
        text: Vue.i18n.translate("store.modules.property.id"),
        value: "id",
      },
      {
        text: Vue.i18n.translate("name"),
        value: "name",
      },
      {
        text: Vue.i18n.translate("actions"),
        value: "action",
        sortable: false,
        align: "right",
      },
    ];
  },
  itemSchema: (state) => {
    const schema = getSchema({
      items: [
        {
          type: "textfield",
          value: "name",
        },
      ],
    });
    return schema;
  },
};

// actions
const actions = {
  async indexProperty({ commit, dispatch }) {
    commit(types.REQUEST);
    try {
      commit(types.SUCCESS_REQUEST, await api.indexProperty());
      commit(types.SET_SELECTED_PROJECT, {
        id: state.propertyId,
      });
      dispatch("getSelectedPropertiesFromLocalstorage");
    } catch (e) {
      commit(types.FAILURE_REQUEST);
    }
  },
  async getPropertyById({ commit }, { id }) {
    commit(types.REQUEST_BY_ID);
    try {
      commit(
        types.SUCCESS_REQUEST_BY_ID,
        await api.getPropertyById({
          id,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_BY_ID);
    }
  },
  async saveOrUpdateProperty({ commit }, { item }) {
    if (item.id) {
      commit(types.REQUEST_UPDATE);
      try {
        commit(
          types.SUCCESS_REQUEST_UPDATE,
          await api.updateProperty({
            id: item.id,
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_UPDATE);
      }
    } else {
      commit(types.REQUEST_SAVE);
      try {
        commit(
          types.SUCCESS_REQUEST_SAVE,
          await api.saveProperty({
            item,
          })
        );
      } catch (e) {
        commit(types.FAILURE_REQUEST_SAVE);
      }
    }
  },
  async deleteProperty({ commit }, { id }) {
    commit(types.REQUEST_DELETE);
    try {
      commit(
        types.SUCCESS_REQUEST_DELETE,
        await api.deleteProperty({
          id,
        })
      );
    } catch (e) {
      commit(types.FAILURE_REQUEST_DELETE);
    }
  },
  removePropertyFromLocalstorageSelectedProperty({ dispatch }, { id }) {
    let arr = JSON.parse(localStorage.getItem("selected-properties")) || [];
    arr = remove(arr, function (arrId) {
      return arrId != id;
    });

    localStorage.setItem("selected-properties", JSON.stringify(arr));
    dispatch("getSelectedPropertiesFromLocalstorage");
  },
  getSelectedPropertiesFromLocalstorage({ commit, state }) {
    const selectedPropertiesFromLocalStorage = JSON.parse(
      localStorage.getItem("selected-properties")
    );
    filter(state.items, (val) => {
      return includes(selectedPropertiesFromLocalStorage, val.id);
    });

    var arr = filter(state.items, (val) => {
      return includes(selectedPropertiesFromLocalStorage, val.id);
    });

    arr = arr.sort((a, b) => {
      return (
        selectedPropertiesFromLocalStorage.indexOf(a.id) -
        selectedPropertiesFromLocalStorage.indexOf(b.id)
      );
    });

    commit(types.SET_RECENTLY_SELECTED_PROJECTS, arr);
  },
  setSelectedProperty({ state, commit, dispatch }, { id = void 0 }) {
    let selectedProperty = {};
    if (id && id.length > 0 && state.items.length > 1) {
      selectedProperty =
        filter(state.items, {
          id,
        })[0] || {};
      addPropertyToLocalstorageSelectedProperty(id);
    } else if (id && id.length > 0 && state.items.length < 2) {
      selectedProperty =
        filter(state.items, {
          id,
        })[0] || {};
      addPropertyToLocalstorageSelectedProperty(id);
    } else {
      selectedProperty = {};
    }
    commit(types.SET_SELECTED_PROJECT, selectedProperty);
    dispatch("getSelectedPropertiesFromLocalstorage");
  },
};

const mutations = {
  [types.REQUEST](state) {
    state.status = "fetching";
  },
  [types.FAILURE_REQUEST](state) {
    state.status = "failure";
  },
  [types.SUCCESS_REQUEST](state, value) {
    state.status = "success";
    state.items = value.body.items;
  },

  [types.REQUEST_SAVE](state) {
    state.statusSave = "fetching";
  },
  [types.FAILURE_REQUEST_SAVE](state) {
    state.statusSave = "failure";
  },
  [types.SUCCESS_REQUEST_SAVE](state, value) {
    state.statusSave = "success";
  },

  [types.REQUEST_UPDATE](state) {
    state.statusUpdate = "fetching";
  },
  [types.FAILURE_REQUEST_UPDATE](state) {
    state.statusUpdate = "failure";
  },
  [types.SUCCESS_REQUEST_UPDATE](state, value) {
    state.statusUpdate = "success";
  },

  [types.REQUEST_DELETE](state) {
    state.statusDelete = "fetching";
  },
  [types.FAILURE_REQUEST_DELETE](state) {
    state.statusDelete = "failure";
  },
  [types.SUCCESS_REQUEST_DELETE](state, value) {
    state.statusDelete = "success";
  },

  [types.REQUEST_BY_ID](state) {
    state.statusById = "fetching";
  },
  [types.FAILURE_REQUEST_BY_ID](state) {
    state.statusById = "failure";
  },
  [types.SUCCESS_REQUEST_BY_ID](state, value) {
    state.statusById = "success";
    state.itemById = value.body;
  },

  [types.SET_SELECTED_PROJECT](state, value) {
    state.propertyId = value.id;
    state.selectedProperty = value;
  },
  [types.SET_RECENTLY_SELECTED_PROJECTS](state, value) {
    state.recentlySelectedProperties = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
