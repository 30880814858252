<template>
  <v-footer height="auto" app>
    <v-card class="transparent" flat tile>
      <v-card-actions class="justify-center">
        &copy;
        {{
          `${new Date().getFullYear()} - at-visions | ${$t(
            "components.core.Footer.rightsReserved"
          )}`
        }}
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
