import {
  authTokenResource,
  authInfoResource,
  authLogoutResource,
  authVerifyTwoFactorAuthCodeResource,
  authResendTwoFactorAuthCodeResource,
} from "@/api/resources";

export const postToken = ({ email, password }) => {
  return authTokenResource.save(
    {},
    {
      email,
      password,
    }
  );
};

export const getInfo = () => {
  return authInfoResource.get();
};

export const logout = () => {
  return authLogoutResource.get();
};

export const verifyTwoFactorAuthCode = ({ email, code }) => {
  return authVerifyTwoFactorAuthCodeResource.save(
    {},
    {
      email,
      code,
    }
  );
};

export const resendTwoFactorAuthCode = ({ email }) => {
  return authResendTwoFactorAuthCodeResource.save(
    {},
    {
      email,
    }
  );
};
